<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.bh-table-responsive input[type=checkbox] {
    width: 2rem;
    height: 1rem;
    opacity: 1;
    position: inherit;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed" @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="p-5 pt-2">
                <div class="py-4">
                    <h2>Lista de roles</h2>
                    <div class="text-end">
                        <a class="btn btn-success" href="roles/create/new">agregar roles</a>
                    </div>
                </div>
                <div v-if="items.length == 0" class="text-center">
                    <h2 class="mt-5">No hay roles ingresados</h2>
                </div>
                <div v-if="items.length > 0">
                    <div class="mb-5">
                        <input v-model="search" type="text" class="form-input max-w-xs" placeholder="Buscar roles..." />
                    </div>

                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'PAIS'">

                        <template #actions="data">
                            <div class=" form-switch">
                                <input class="form-check-input" type="checkbox" role="switch"
                                    id="flexSwitchCheckChecked" @click="actionRol(data.value)"
                                    :checked="data.value.status == 1" />
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
    </div>
</template>
<script>

import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
export default {
    components: {
        Vue3Datatable,
        NavBar,
        BarTop,
        Vue3Datatable,
        ref
    },
    data() {
        return {
            collapsed: true,
            pinned:localStorage.getItem('pinned') == 'false'?false:true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            search: '',
            items: [

            ],
            clientesSelect: [],
            columns: [
                { field: "id_rol", title: "ID", width: "90px", filter: false },
                { field: "name", title: "NOMBRE" },
                { field: "description", title: "DESCRIPCION" },
                { field: 'actions', title: 'ACCION', sortable: false },

            ]
        }
    },
    mounted() {
        this.getRoles()
        document.body.className = this.theme;
    },
    computed: {

    },
    methods: {
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        statusRol(data, url) {
            this.$swal({
                title: 'Actualizando rol'
            });
            this.$swal.showLoading();


            axios.put('/api/roles/' + data.id_client + '/' + url, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                if (response.status == 200) {
                    this.getClients()
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "warning",
                        title: "Error!",
                        text: "Se a producido un error al actualizar roles.",
                        showConfirmButton: false,

                        showCancelButton: false
                    });
                }


            })
        },
        actionRoles(data) {
            const self = this;
            this.$swal({
                title: 'Actualizando roles'
            });
            this.$swal.showLoading();
            let check = data.status == 1;
            this.$swal.fire({
                icon: "warning",
                title: check ? 'Deseas desactivar este roles?' : 'Deseas activar este roles?',
                showConfirmButton: true,
                confirmButtonText: "si",
                showCancelButton: true,
                cancelButtonText: "no"
            }).then((result) => {
                if (result.isConfirmed) {
                    if (check) {
                        data.status = 2
                        self.statusClient(data, 'delete');
                    } else {
                        data.status = 1
                        self.statusClient(data, 'reactivate');
                    }

                } else {
                    this.$swal.close();
                    self.verModal = true;
                }
            });
        },
        getRoles() {
            this.$swal({
                title: 'Obteniendo roles'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/roles', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.items = response.data;

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });;
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.bh-table-responsive input[type=checkbox] {
    width: 2rem;
    height: 1rem;
    opacity: 1;
    position: inherit;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed" @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="p-5 pt-2">
                <div class="py-4">
                    <h2 class="">Lista de usuarios</h2>
                    <div class="text-end">
                        <a class="btn btn-success" href="usuarios/create">Agregar usuario</a>
                    </div>
                </div>
                <div v-if="items.length == 0" class="text-center">
                    <h2 class="mt-5">No hay usuarios ingresados</h2>
                </div>
                <div v-if="items.length > 0">
                    <div class="mb-5">
                        <input v-model="search" type="text" class="form-input max-w-xs"
                            placeholder="Buscar usuario..." />
                    </div>

                    <vue3-datatable :search="search" 
                                    :rows="items" 
                                    :columns="columns"
                                    order:sortable="true"
                        :sortColumn="'ID'">

                        <template #id_user="data" >
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                {{ data.value.id_user }}
                            </div>
                        </template>

                        <template #email="data" >
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                {{ data.value.email }}
                            </div>
                        </template>

                        <template #name="data" >
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                {{ data.value.name }}
                            </div>
                        </template>

                        <template #rut="data" >
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                {{ data.value.rut }}
                            </div>
                        </template>

                        <template #company="data" >
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                {{ data.value.company.company_name }}
                            </div>
                        </template>
                        <template #status="data">
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                <strong>{{ data.value.status == 1 ? 'HABILITADO' : 'DESHABILITADO' }}</strong>
                            </div>
                        </template>
                        <template #actions="data">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    Acciones
                                </button>
                                <ul class="dropdown-menu">
                                    <li @click="editarUser(data.value.id_client)"><a class="dropdown-item" href="#">
                                            <i class="bi bi-pencil px-2"></i>Editar</a></li>
                                    <li v-if="data.value.status == 1" @click="disableUser(data.value.id_user)"><a
                                            class="dropdown-item" href="#">
                                            <i class="bi bi-toggle2-off px-2"></i>Deshabilitar</a></li>
                                    <li v-else @click="enableUser(data.value.id_user)"><a class="dropdown-item"
                                            href="#">
                                            <i class="bi bi-toggle2-on px-2"></i>Habilitar</a></li>
                                </ul>
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
    </div>
</template>
<script>

import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
export default {
    components: {
        Vue3Datatable,
        NavBar,
        BarTop,
        Vue3Datatable,
        ref
    },
    data() {
        return {
            collapsed: true,
            pinned:localStorage.getItem('pinned') == 'false'?false:true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            search: '',
            items: [

            ],
            UseresSelect: [],
            columns: [
                { field: "id_user", title: "ID", width: "90px", filter: false },
                { field: "name", title: "Nombre" },
                { field: "rol", title: "Rol" },
                { field: "rut", title: "RUT" },
                { field: "company", title: "Empresa" },
                { field: "email", title: "Correo" },
                { field: "status", title: "Estado" },
                { field: 'actions', title: '', style: { whiteSpace: "nowrap", textAlign: "center" } },

            ],
        }
    },
    mounted() {
        this.getUsers()
        document.body.className = this.theme;
    },
    computed: {

    },
    methods: {
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        editarUser(id_user) {
            window.location.href = 'usuarios/create/' + id_user;
        },

        formatRut(rut, withDots) { 
            let parts = rut.split('-');
            let nums = parts[0].replaceAll('.', '');
            if (withDots) {
                let rutnum = new Intl.NumberFormat('es-CL').format(nums);
                return rutnum + "-" + parts[1];
            } else {
                return nums + "-" + parts[1];
            }
        },

        changeUserStatus(id_user, id_status, msg, msg_error) {
            const self = this;

            let urlpost = '/api/users/' + id_user  + '?_method=PATCH' // para editar

            axios.post(urlpost,
                {
                    status: id_status,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then(response => {
                    //console.log(response.data)
                    // self.$swal.close();
                    if (response.status == 200) {
                        self.$swal.fire({
                            title: msg,
                            icon: "info",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Ok"
                            }).then((result) => {
                                this.getUsers();
                            });
                    } else {
                        // console.log(response.data);

                        self.$swal.fire({
                            icon: "error",
                            title: msg_error,
                            text: "Comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                            });
                    }
                }).catch(function (error) {

                    if ( Object.hasOwn(error.response,"statusText") ) {
                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        } else {
                            self.$swal.fire({
                                icon: "error",
                                title: "Error",
                                text: "Problema modificando usuario detectado, comuníquese con soporte.",
                                showConfirmButton: false,
                                showCancelButton: false
                            });
                        }
                    } else {
                        console.log(error);
                        self.$swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Problema interno detectado, comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });

                    }
                });
        },

        disableUser(id_user) {
            const self = this;
    
            this.$swal.fire({
                title: "¿Está seguro que desea deshabilitar este usuario?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, deshabilitar"
                }).then((result) => {
                if (result.isConfirmed) {
                    self.changeUserStatus(id_user, 
                                            2, 
                                            "usuario deshabilitado exitosamente", 
                                            "error al deshabilitar usuario");
                }
            });
        },


        enableUser(id_user) {
            const self = this;
    
            this.$swal.fire({
                title: "¿Está seguro que desea habilitar este usuario?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, habilitar"
                }).then((result) => {
                if (result.isConfirmed) {
                    self.changeUserStatus(id_user, 
                                            1, 
                                            "usuario habilitado exitosamente", 
                                            "error al habilitar usuario");
                }
            });
        },
        
        getUsers() {
            this.$swal({
                title: 'Obteniendo usuarios'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/users', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.map((user)=>{
                        user.rut = self.formatRut(user.rut, true)
                        let rol = ""; 
                        if(user.user_rol.length > 0) {
                            rol = user.user_rol[0]['rol']['name'];
                        }
                        user["rol"] = rol;
                        return user
                    });

                    //self.UseresSelect = response.data.UsereEmpresa;
                    this.$swal.close();
                } else {
                    self.Users = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });;
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
<style>
.bh-table-responsive {
    min-height: 30vh;
}

.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="mx-4">Notificaciones</h2>
                </div>
                <div class="">
                    <!-- <div class="row mx-4">
                        <div class="col-md-4 mb-4">
                            <label for="historialType" class="form-label">Tipo</label>
                            <select class="form-select" placeholder="seleccione un tipo"
                                aria-label="Default select example" v-model="historialType">
                                <option value="">Todas las alertas</option>
                                <option :value="1">leido</option>
                                <option :value="2">no leido</option>
                            </select>
                        </div>
                        <div class="col-md-2 mt-4">
                            <div class="text-end">
                                <a class="btn btn-success" @click="filterHistory()">Filtrar</a>
                            </div>
                        </div>
                    </div> -->
                    <div class="card-body">
                        <div v-for="alert in alerts" :key="alert.id" @click="handleAlertClick(alert)"
                            :class="['card', 'p-4', 'm-3', { 'bg-dark text-white': alert.is_read === 0 }]">
                            <h6>{{ alert.title }}</h6>
                            <p>{{ alert.message }}</p>
                        </div>
                        <div v-if="alerts.length == 0">
                            <br><br>
                            <p class="text-center">Sin notificaciones</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>


    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";


export default {
    components: {
        VueDatePicker,
        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,

            historialType: "",
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            alerts: [],
            user: {}
        }
    },
    mounted() {

        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        this.getAlert(this.user.id_user);
        document.body.className = this.theme;
    },
    computed: {

    },
    methods: {
        filterHistory() {

        },
        async handleAlertClick(alert) {
            try {
                // Actualiza el estado en el servidor
                await axios.get(`/api/alerts/${alert.id_alert}/mark-as-read`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                })
                // Actualiza localmente
                alert.is_read = 1;

                // Redirige a la URL
                window.location.href = alert.url;
            } catch (error) {
                console.error("Error al marcar la alerta como leída:", error);
            }
        },
        getAlert(id) {
            this.$swal({
                title: 'Obteniendo alertas'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/user/' + id + '/alerts', {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.alerts = response.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';



.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
<style scoped>
.nav-link {
  font-size: 1.2rem;
  color: black;
}
</style>
<template>
  <div class="topBar textSt p-3">
    <ul class="nav justify-content-end">
      <li class="nav-item dropdown textSt">
        <a class="nav-link dropdown-toggle textSt" data-bs-toggle="dropdown" href="#" role="button"
          aria-expanded="false">
          <i v-if="theme == 'dark-mode'" class="bi bi-moon-stars-fill pe-2 textSt"></i>
          <i v-if="theme == 'light-mode'" class="bi bi-brightness-high-fill pe-2 textSt"></i>
          <i v-if="theme == themeClient" class="bi bi-award-fill pe-2 textSt"></i>
        </a>
        <ul class="dropdown-menu textLt">
          <li @click="toggleTheme('dark-mode')"><a class="dropdown-item textSt" href="#"> <i
                class="bi bi-moon-stars-fill pe-2 textSt"></i> Modo nocturno</a></li>
          <li @click="toggleTheme('light-mode')"><a class="dropdown-item textSt" href="#"> <i
                class="bi bi-brightness-high-fill pe-2 textSt"></i> Modo dia</a></li>
          <li @click="toggleTheme(themeClient)"><a class="dropdown-item textSt" href="#"> <i
                class="bi bi-award-fill pe-2 textSt"></i> Modo personalizado</a></li>
        </ul>
      </li>
      <li class="nav-item">
        <div class="nav-link" @click="toggleNotifications">
          <i class="bi bi-bell textSt"></i>
          <!-- Circulo rojo con el número de notificaciones -->
          <span v-if="alerts.length > 0" class="notification-badge">
            {{ alerts.length }}
          </span>
        </div>
        <!-- Desplegable de alertas -->
        <div v-if="showNotifications" class="notifications-dropdown">
          <div class="cont">
            <h5>Notificaciones</h5>
            <ul v-if="alerts.length > 0">
              <li v-for="alert in alerts" :key="alert.id" :class="{ 'unread': !alert.is_read }" @click="handleAlertClick(alert)">
                <a style="text-decoration: none; color: inherit;">
                  <strong style="font-size: 0.9rem;">{{ alert.title }}</strong>
                  <small style="font-size: 0.8rem;">{{ alert.message }}</small>
                  <br>
                  <small>{{ alert.created_at }}</small>
                </a>
              </li>
            </ul>
            <ul v-else>
              <p>Sin notificaciones pendientes</p>
            </ul>
          </div>
          <div class="text-center">
            <a href="/alerts">Ver todas las notificaciones</a>
          </div>

        </div>
      </li>
      <li class="nav-item">
        <div class="nav-link">
          <i class="bi bi-chat textSt"></i>
        </div>
      </li>
      <li class="nav-item dropdown textSt">
        <div class="nav-link dropdown-toggle textSt" data-bs-toggle="dropdown" href="#" role="button"
          aria-expanded="false">
          <i class="bi bi-person-circle pe-2 textSt"></i>
          <span>{{ user.name }}</span>
        </div>

        <ul class="dropdown-menu textLt">

          <li><a class="dropdown-item textSt" href="#">Reportar Error</a></li>
          <li><a class="dropdown-item textSt" href="#">Configuracion</a></li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li><a class="dropdown-item textSt" @click="logOut()">Cerrar Session</a></li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      theme: localStorage.getItem('theme') || 'light-mode',
      themeClient: '',
      currentUrl: '',
      alerts: [],
      showNotifications: false,
      user: {
        name: '',
        id_client: ''
      }
    }
  },
  computed: {

  },
  mounted() {
    document.body.className = this.theme;
    this.currentUrl = window.location.pathname;

    let sessionData = localStorage.getItem('user');
    this.user = JSON.parse(sessionData);
    this.themeClient = this.user.company.skin;

    window.Echo.connector.pusher.connection.bind('connected', () => {
      console.log("Conexión exitosa con Pusher");
    });

    window.Echo.channel(`user.${this.user.id_user}`)
      .listen('.new-alert', (e) => {
        this.alerts.push(e.alert);
        console.log("Alerta recibida 2:", e);
      });
    this.getAlerts(this.user.id_user)
    //this.isLogin();
  },
  methods: {
    async handleAlertClick(alert) {
      try {
        // Actualiza el estado en el servidor
        await axios.get(`/api/alerts/${alert.id_alert}/mark-as-read`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        })
        // Actualiza localmente
        alert.is_read = 1;

        // Redirige a la URL
        window.location.href = alert.url;
      } catch (error) {
        console.error("Error al marcar la alerta como leída:", error);
      }
    },
    showNotification(alert) {
      // Mostrar la notificación en pantalla
      console.log("Nueva alerta:", alert);
      // Aquí puedes usar librerías como Toastr o implementaciones propias
    },
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
    },
    async getAlerts(id) {
      const self = this;
      try {

        const response = await axios.get('/api/user/' + id + '/alertsunread', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        });
        console.log(response.data)
        self.alerts = response.data;
      } catch (error) {
        console.error('Error alert get:', error);
      }

    },
    logOut() {
      localStorage.removeItem('accessToken');
      window.location.href = './../../api/auth/logout'
    },
    toggleTheme(theme) {
      this.theme = theme;
      document.body.className = this.theme;
      localStorage.setItem('theme', this.theme);
    },
  },
};
</script>
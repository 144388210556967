<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3"><b>Abonar desde provisión Pago a proveedor #{{ id_payment }}</b>
                        </h2>
                        <label for="date" class="form-label"><b>Monto impago : {{ dataForm.payment.unpaid_amount
                                }}</b></label>
                        <p>Permite registrar un abono para pagar un gasto a proveedor.
                            Este abono se descontará de una provisión.
                        </p>

                    </div>
                    <p>Provisiones asociadas al cliente <b>{{ clientName }}</b></p>

                    <vue3-datatable :search="search" :rows="supply" :columns="columns" order :sortable="true"
                        :sortColumn="'date'">
                        <template #actions="data">
                            <button @click="toggleSelectionSupply(data.value)">
                                {{ isSelectedSupp(data.value) ? 'Desasociar' : 'Asociar' }}
                            </button>
                        </template>
                        <template #item="data">
                            <span>{{ transformItem(data.value.client_supply_concept) }}</span>

                        </template>
                        <template #amount="data">
                            <span>{{ data.value.amount > 0 ? formatCurrency(data.value.amount,
            data.value.currency.initials) : 0 }}</span>

                        </template>

                    </vue3-datatable>
                    <div class="row pt-4 px-1">
                        <div class="col-md-3 mb-4">
                            <label for="date" class="form-label">Fecha</label>
                            <div class="input-group mb-3">
                                <VueDatePicker v-model="dataForm.date.value" locale="es" format="dd/MM/yyyy">
                                </VueDatePicker>
                            </div>
                            <span v-if="dataForm.date.err" class="text-danger">Debe seleccionar una fecha</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="mount" class="form-label">Monto</label>
                            <input class="form-control" :class="{ 'border-danger': dataForm.mount.err }" id="mount"
                                placeholder="" v-model="dataForm.mount.value_formated" @blur="handlerBlur"
                                @input="formatAmountAndUpdate(dataForm.mount, dataForm.currency)">
                            <span v-if="dataForm.mount.err" class="text-danger">Debe ingresar un
                                monto</span>
                        </div>
                        <div class="col-md-6">
                            <label for="description" class="form-label">Observación</label>
                            <textarea type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.description.err }" id="description"
                                placeholder="Ingrese un mensaje" v-model="dataForm.description.value"
                                @blur="handlerBlur">
        </textarea>
                            <p v-if="dataForm.description.err" class="text-danger">Debe ingresar una
                                observacion</p>
                        </div>
                    </div>
                    <hr>
                    <p>En caso de abonar en moneda distinta a la del pago a proveedor original, llenar:</p>
                    <div class="row">
                        <div class="col-md-3 mb-4">
                            <label for="documentNumber" class="form-label">Tasa de cambio</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm3.exchangeRate.err }" id="exchangeRate"
                                placeholder="" v-model="dataForm3.exchangeRate.value" @blur="handlerBlur">
                            <span v-if="dataForm3.exchangeRate.err" class="text-danger">Debe ingresar una tasa de cambio
                                válida</span>
                        </div>
                        <div class="col-md-5 mb-4">
                            <label for="valueMoney" class="form-label">Valor en moneda original de este abono</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm3.valueMoney.err }" id="valueMoney" placeholder=""
                                v-model="dataForm3.valueMoney.value" @blur="handlerBlur">
                            <span v-if="dataForm3.valueMoney.err" class="text-danger">Debe ingresar un número de
                                documento</span>
                        </div>
                    </div>
                    <hr>


                    <div class="row">


                        <div class="text-center text-lg-end mt-4 pt-2">
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="create()">
                                Abonar
                            </button>
                            <button type="button" class="btn btn-md text-white bg-danger" @click="backPage()"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;">
                                Cancelar
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </main>

    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import DropFile from './../../component/DropFile.vue';
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../../component/NavBar.vue';
import BarTop from './../../component/BarTop.vue';
import NumUtils from './../../component/NumUtils.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DropFile,
        DataTable,
        DataTablesCore,
        NavBar,
        BarTop,
        NumUtils,
        Vue3Datatable
    },
    data() {
        return {
            collapsed: true,
            verClientSupply: false,
            supply: [
            ],
            clientName: '',
            columns: [
                { field: "date_payment", title: "Fecha" },
                { field: "amount", title: "Monto", filter: false },

                { field: "item", title: "Ítems" },
                { field: 'actions', title: '', sortable: false },

            ],
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            idClient: 0,
            dataForm3: {
                exchangeRate: {
                    value: 1,
                    err: false,
                    msjErr: ''
                },
                valueMoney: {
                    value: '',
                    err: false,
                    msjErr: ''
                }
            },
            dataForm: {
                sending: false,
                isSupply: false,
                supplySelect: {},
                payment: {},
                date: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                currency: { // este campo se usa para formatear el monto según la divisa
                    value: '', // * no borrar *
                    err: false,
                },
                mount: {
                    value: '', // monto que efectivamente se envía a guardar
                    value_formated: '', // monto que se muestra por pantalla (input)
                    err: false,
                    msjErr: ''
                },
                id_client: {
                    value: 1,
                    err: false,
                    msjErr: ''
                },
                description: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                id_payment: 0,
                unpaid_amount: 0
            }
        }
    },
    mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);

        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');

        if (parts.length == 5) {
            if (!isNaN(Number(parts[3]))) {
                this.id_payment = Number(parts[3]);
                this.dataForm.id_payment = this.id_payment
                this.getPayment(this.id_payment);
            }
            if (!isNaN(Number(parts[4]))) {
                this.idClient = Number(parts[4]);
                this.getSupply(this.idClient);
                this.getClient(this.idClient);
               
            }
        }

        this.getShipping();
        this.getBillNotes();
        this.getCurrencies();
        this.paymentMethodes = [{
            id: 1,
            name: 'Transferencia electronica',
        },
        {
            id: 2,
            value: 'Pago tarjeta de credito',
        }, {
            id: 3,
            value: 'Pago en efectivo',
        }]
    },
    computed: {

    },
    methods: {
        backPage() {
            window.history.back();
        },
        isSelectedSupp(data) {
            return this.dataForm.supplySelect == data
        },
        formatAmountAndUpdate(amountInput, currencySelect) {
            NumUtils.methods.formatAmountAndUpdate(amountInput, currencySelect);
        },
        toggleSelectionSupply(data) {
            if (this.dataForm.supplySelect == data) {
                this.dataForm.isSupply = false;
                this.dataForm.supplySelect = {};
            } else {
                this.dataForm.isSupply = true;
                this.dataForm.supplySelect = data;
            }
        },
        transformItem(item) {
            // Úne el array en una cadena separada por comas y espacios

            return item.map(concept => concept.concept.name)
                .join(', ');;
        },
        formatCurrency(value, currencyCode = 'CLP') {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        async getPayment(idPayment) {
            try {
                const response = await axios.get('/api/services-to-be-collected/' + idPayment, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                console.log(response.data)
                this.dataForm.payment = response.data.data;
                this.dataForm.currency.value = response.data.data['id_currency']
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        isSelectedShipp(data) {
            return this.dataForm3.shippingSelect == data
        },
        toggleSelectionShipping(data) {

            if (this.dataForm3.shippingSelect == data) {
                this.dataForm3.isShipping = false;
                this.dataForm3.shippingSelect = {};
            } else {
                this.dataForm3.isShipping = true;
                this.dataForm3.shippingSelect = data;
            }

        },
        
        async getBillNotes() {
            const self = this;

            const req = {
                page: 1,
                per_page: 10
            }

            axios.post('/api/getBillingNotes', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {

                if (response.status == 200) {

                    self.billNotes = response.data.data

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },
        async getShipping() {
            const self = this;

            const req = {
                page: 1,
                per_page: 10
            }

            axios.post('/api/getShippings', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {

                if (response.status == 200) {

                    self.shippings = response.data.data

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },
        async getClient(id) {
            const self = this;
            try {
                const response = await axios.get('/api/clients/' + id, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });

                self.clientName = response.data.name;

            } catch (error) {
                console.error('Error fetching supply:', error);
            }
        },
        async getSupply(idClient) {
            const self = this;
            try {
                const response = await axios.get('/api/clientSupply/' + idClient, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });

                self.supply = response.data;

            } catch (error) {
                console.error('Error fetching supply:', error);
            }
        },
        closeModal() {
            const self = this;
            self.verClientSupply = false;
        },
        closeModal2() {
            const self = this;
            self.verShipping = false;
        },
        closeModal3() {
            const self = this;
            self.verBillNote = false;
        },
        async getCurrencies() {
            try {
                const response = await axios.get('/api/currencies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.currencies = response.data.filter((divisa) => {
                    return divisa.status == 1
                });
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        },
        updateCollapsed(value) {

            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {

            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;

        },
        create() {
            const self = this;

            self.dataForm.id_client.value = self.idClient;
            self.dataForm.sending = true;

            let errFound = false;

            if (self.dataForm.date.value == '') {
                self.dataForm.date.err = true;
                errFound = true;
            }
            if (self.dataForm.mount.value == '') {
                self.dataForm.mount.err = true;
                errFound = true;
            }


            if (self.dataForm.description.value == '') {
                self.dataForm.description.err = true;
                errFound = true;
            }

            if (self.dataForm3.exchangeRate.value == '' || self.dataForm3.exchangeRate.value == 0) {
                self.dataForm.exchangeRate.err = true;
                errFound = true;
            }

            if (errFound) {
                return false;
            }

            this.$swal({
                title: 'Creando abono...'
            });
            this.$swal.showLoading();
            const req = { form: self.dataForm, form2: this.dataForm2, form3: this.dataForm3 }

            axios.post('/api/createCsFeeSpend',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (response.status == 200 || response.status == 201) {
                        self.$swal.fire({
                            icon: "success",
                            title: "abono guardado exitosamente",
                            showConfirmButton: true,
                            showCancelButton: false
                        }).then((result) => {

                            window.location.href = '../../../serviciosCobrar';
                        });
                        //self.$swal.close();
                    } else {
                        self.$swal.fire({
                            icon: "error",
                            title: response.message,
                            text: "Error detectado, comuníquese con soporte ",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                }).catch(function (error) {

                    self.$swal.fire({
                        icon: "error",
                        title: msg_error,
                        text: "Error al registrar abono (provisión), comuníquese con soporte ",
                        showConfirmButton: false,
                        showCancelButton: false
                    });

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });


        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4 ">
                    <div>
                        <h2 class="fw-normal mb-4 me-3"><b>Crear nota de cobro</b></h2>
                        <p>Una nota de cobro contiene un listado de pagos a proveedor (gastos) que serán enviados
                            como cobranza a un cliente. </p>
                    </div>
                    <div class="row pt-4 px-1">

                        <div class="col-4 mb-4">
                            <label for="client" class="form-label">Seleccionar cliente</label>
                            <select @change="clientSelected($event)" class="form-select"
                                aria-label="Default select example" v-model="dataForm.client.value">
                                <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                                </option>
                            </select>
                            <span v-if="dataForm.client.err" class="text-danger">Debe seleccionar un cliente</span>
                        </div>

                        <div class="col-4 mb-4">
                            <label for="date_emit" class="form-label">Fecha de emisión (seleccionar solo si va a crear y
                                emitir)</label>
                            <div class="input-group mb-3">
                                <VueDatePicker v-model="dataForm.date_emit.value" locale="es" format="dd/MM/yyyy">
                                </VueDatePicker>
                            </div>
                            <span v-if="dataForm.date_emit.err" class="text-danger">Debe escoger una fecha</span>
                        </div>


                    </div>

                    <div class="row pt-0 px-1">

                        <div class="col-4 mb-4">
                            <label for="currency" class="form-label">Seleccionar divisa</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.currency.value" @change="formatAmountAndUpdate(dataForm.billing_note_amount,
            dataForm.currency)">
                                <option v-for="currency in currencies" :value="currency.id_currency">{{ currency.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-4 mb-4">
                            <label for="numberBill" class="form-label">Número de documento</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.numberBill.err }" id="numberBill" placeholder=""
                                v-model="dataForm.numberBill.value" @blur="handlerBlur">
                            <span v-if="dataForm.numberBill.err" class="text-danger">Debe ingresar un número de nota de
                                cobro</span>
                        </div>


                    </div>

                    <div class="row pt-4 px-1">
                        <div class="col-4 mb-4">
                            <label for="billing_note_amount" class="form-label">Monto</label>
                            <input type="text" class="form-control" disabled
                                :class="{ 'border-danger': dataForm.billing_note_amount.err }" id="billing_note_amount"
                                placeholder="" v-model="dataForm.billing_note_amount.value_formated" @input="formatAmountAndUpdate(dataForm.billing_note_amount,
            dataForm.currency)" @blur="handlerBlur">
                            <span v-if="dataForm.billing_note_amount.err" class="text-danger">Debe ingresar un
                                monto</span>
                        </div>
                    </div>
                    <div v-if="dataForm.pagoserr == false" class="text-dark">Pagos a proveedor asociados:
                        <span class="text-info" v-for="(bill, ind) in selectedItems">
                            &ensp;"{{ bill.id_payment }}"
                        </span>
                    </div>

                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'date_payment'">
                        <template #select="data">
                            <button @click="toggleSelection(data.value)">
                                {{ isSelected(data.value) ? 'Desasociar' : 'Asociar' }}
                            </button>
                        </template>
                        <template #value_payment="data">
                            <span>{{ data.value.value_payment > 0 ? formatCurrency(data.value.value_payment,
            data.value.value_payment_initials) : 0 }}</span>
                        </template>
                        <template #unpaid_amount="data">
                            <span>{{ data.value.unpaid_amount > 0 ? formatCurrency(data.value.unpaid_amount,
            data.value.value_payment_initials) : 0 }}</span>
                        </template>
                    </vue3-datatable>
                    <div class="text-center text-lg-end mt-4 pt-2">
                        <button type="button" class="btn btn-md text-white mx-2"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                            @click="create(true)">
                            Crear y emitir
                        </button>
                        <button type="button" class="btn btn-md text-white mx-2 bg-success"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;" @click="create(false)">
                            Guardar sin emitir
                        </button>
                        <button type="button" class="btn btn-md text-white bg-danger" @click="backPage()"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;">
                            Cancelar
                        </button>
                    </div>

                </div>
            </div>


        </main>

        <!-- Modal ar -->
        <div class="modal-mb" v-if="verModal">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Pagos a proveedor</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card">
                            <div class="">

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../component/NavBar.vue';
import NumUtils from './../component/NumUtils.vue';
import BarTop from './../component/BarTop.vue';

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DataTable,
        DataTablesCore,
        NavBar,
        BarTop,
        NumUtils,
        Vue3Datatable
    },
    data() {
        return {
            collapsed: true,
            verModal: false,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            selectedItems: new Set(),
            items: [

            ],
            clients: [

            ],
            columns: [
                { field: 'select', title: 'Seleccionar' },
                { field: "id_payment", title: "ID" },
                { field: "shipping_number", title: "Nro. despacho" },
                { field: "date_payment", title: "Fecha" },
                { field: "week_number", title: "Número de semana" },
                { field: "concept", title: "Concepto" },
                { field: "value_payment", title: "Monto deuda original" },
                { field: "unpaid_amount", title: "Monto impago" },
                { field: "value_payment_initials", title: "Divisa" }
            ],
            idClient: 0,
            currencySelect: '',
            dataForm: {
                sending: false,
                pagoserr: false,
                numberBill: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                date_emit: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                currency: {
                    value: 15,
                    err: false,
                },
                billing_note_amount: {
                    value: 0, // monto que efectivamente se envía a guardar
                    value_formated: 0, // monto que se muestra por pantalla (input)
                    err: false,
                },
                client: {
                    value: '',
                    err: false,
                }
            }
        }
    },
    mounted() {
        this.getClients();
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        this.idClient = this.user.company.id;
        this.getCurrencies();
        // this.getAR()
    },
    computed: {
        isSelected() {
            // Computed para validar si el elemento está seleccionado
            return (value) => this.selectedItems.has(value);
        }
    },
    methods: {
        formatCurrency(value, currencyCode = 'CLP') {

            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        backPage() {
            window.history.back();
        },
        formatAmountAndUpdate(amountInput, currencySelect) {
            NumUtils.methods.formatAmountAndUpdate(amountInput, currencySelect);
        },

        async getCurrencies() {
            try {
                const response = await axios.get('/api/currencies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.currencies = response.data.filter((divisa) => {
                    return divisa.status == 1
                });
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        },
        toggleSelection(value) {
            const self = this
            self.verModal = false;
            if (self.currencySelect == '') {
                self.currencySelect = value.value_payment_initials
            } 
            if (self.currencySelect == value.value_payment_initials) {
                if (self.selectedItems.has(value)) {
                    self.selectedItems.delete(value); // Elimina el valor si está seleccionado
                    self.dataForm.billing_note_amount.value_formated = Number(self.dataForm.billing_note_amount.value_formated) - Number(value.unpaid_amount);
                    self.dataForm.billing_note_amount.value =  Number(self.dataForm.billing_note_amount.value) - Number(value.unpaid_amount);
                } else {
                    self.selectedItems.add(value); // Agrega el valor si no está seleccionado
                    self.dataForm.billing_note_amount.value_formated = Number(self.dataForm.billing_note_amount.value_formated) + Number(value.unpaid_amount);
                    self.dataForm.billing_note_amount.value = Number(self.dataForm.billing_note_amount.value) + Number(value.unpaid_amount);
                    // self.verModal = true;
                }
                // Aseguramos que Vue detecte el cambio
                self.selectedItems = new Set(self.selectedItems);
            }else{
                self.$swal.fire({
                            icon: "error",
                            title: "No se pueden agregar 2 pagos con distinta divisa",
                            showConfirmButton: true,
                            confirmButtonText: "ok",
                            showCancelButton: false
                        })
            }
            console.log(self.dataForm.billing_note_amount.value);
            console.log(self.dataForm.billing_note_amount.value_formated)
        },

        closeModal() {
            const self = this
            self.verModal = false;
        },
        getAR() {
            this.$swal({
                title: 'Obteniendo pago a proveedores'
            });
            this.$swal.showLoading();
            const self = this;
            let req = {
                page: 1,
                per_page: 50,
                id_client: self.dataForm.client.value,
                only_last_not_paid: true
            }

            axios.post('/api/getSupplierPayments', req, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data.filter((payment) => Number(payment.unpaid_amount) > 0 && payment.id_payment_status != 1 && payment.emitted_notes == "" && payment.id_payment_status != 5)
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.items = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
            console.log(id);
        },
        create(isEmit) {
            const self = this;

            self.dataForm.sending = true;

            let errFound = false;
            const validateField = (field) => {
                if (field.value === '' || field.value === 0 || !field.value) {
                    field.err = true;
                    //console.log("vacio");
                    errFound = true;
                } else {
                    field.err = false;
                }
            };

            Object.keys(this.dataForm).forEach(key => {
                if (typeof this.dataForm[key] === 'object' && 'value' in this.dataForm[key]) {
                    //console.log(key);
                    if (isEmit) {
                        validateField(this.dataForm[key]);
                    } else {
                        // si no se está emitiendo no debe haber fecha de emisión
                        if (key == "date_emit") {
                            this.dataForm[key].value = '';
                        } else {
                            validateField(this.dataForm[key]);
                        }
                    }
                }
            });

            let args = Array.from(this.selectedItems);

            if (args.length == 0) {
                self.dataForm.pagoserr = true;
                errFound = true;
            } else {
                self.dataForm.pagoserr = false;
            }

            if (errFound) {
                return false;
            }

            this.$swal({
                title: 'Creando nota de cobro...'
            });

            this.$swal.showLoading();
            const req = { form: self.dataForm, selectPass: args, isEmit: isEmit }

            axios.post('/api/createBillingNote',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    console.log(response);
                    if (response.status == 200) {
                        self.$swal.fire({
                            icon: "success",
                            title: "nota de cobro guardada exitosamente",
                            showConfirmButton: true,
                            confirmButtonText: "ok",
                            showCancelButton: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '../notascobro'
                            }
                        });

                    } else {

                    }
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    } else {

                        self.$swal.fire({
                            icon: "error",
                            title: "Error interno, comuníquese con soporte",
                            showConfirmButton: true,
                            confirmButtonText: "ok",
                            showCancelButton: false
                        });

                    }
                });

        },

        getClients() {
            this.$swal({
                title: 'Obteniendo clientes'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.clients = response.data;

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });

        },

        clientSelected(value) {
            //console.log('Selected Option:', value);
            this.selectedOption = value;
            const self = this;
            self.getAR();
        },

    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
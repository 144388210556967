<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.bh-table-responsive input[type=checkbox] {
    width: 2rem;
    height: 1rem;
    opacity: 1;
    position: inherit;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed" @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="p-5 pt-2">
                <div class="py-4">
                    <h2 class="">Lista de divisas</h2>
                </div>
                <div v-if="items.length == 0" class="text-center">
                    <h2 class="mt-5">No hay divisas ingresadas</h2>

                </div>
                <div v-if="items.length > 0">
                    <div class="mb-5">
                        <input v-model="search" type="text" class="form-input max-w-xs"
                            placeholder="Buscar divisa..." />
                    </div>
                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'PAIS'">
                        <template #id="data">
                            <strong>{{ data.value.id }}</strong>
                        </template>
                        <template #actions="data">
                            <div class=" form-switch">
                                <input class="form-check-input" type="checkbox" role="switch"
                                    id="flexSwitchCheckChecked" @click="accionDivisa(data.value)"
                                    :checked="ischecked(data.value.id)" />
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
    </div>
</template>
<script>

import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
export default {
    components: {
        Vue3Datatable,
        NavBar,
        BarTop,
        Vue3Datatable,
        ref
    },
    data() {
        return {
            collapsed: true,
            pinned:localStorage.getItem('pinned') == 'false'?false:true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            search: '',
            items: [

            ],
            divisasSelect: [],
            columns: [
                { field: "id", title: "ID", width: "90px", filter: false },
                { field: "nombre", title: "NOMBRE" },
                { field: "pais", title: "PAIS" },
                { field: "sigla", title: "SIGLA" },
                { field: 'actions', title: 'ACCION', sortable: false },

            ]
        }
    },
    mounted() {
        this.getDivisa()
        document.body.className = this.theme;
    },
    computed: {

    },
    methods: {
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        ischecked(data) {
            const self = this;
            let divisaF = self.divisasSelect.filter(divisa => divisa.id_divisa === data);

            if (divisaF.length > 0) {
                return true
            } else {
                return false;
            }

        },
        activarDivisa(data) {

            const req = { idDivisa: data.id, status: 1 }

            axios.post('/api/currencies/status', req, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                if (response.status == 200) {
                    this.getDivisa()
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "warning",
                        title: "Error!",
                        text: "Se a producido un error al actualizar divisa.",
                        showConfirmButton: false,

                        showCancelButton: false
                    });
                }


            })
        },
        desactivarDivisa(data) {
            this.$swal({
                title: 'Actualizando divisas'
            });
            this.$swal.showLoading();

            const req = { idDivisa: data.id, status: 2 }

            axios.post('/api/currencies/status', req, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                if (response.status == 200) {
                    this.getDivisa()
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "warning",
                        title: "Error!",
                        text: "Se a producido un error al actualizar divisa.",
                        showConfirmButton: false,

                        showCancelButton: false
                    });
                }


            })
        },
        accionDivisa(data) {
            const self = this;
            this.$swal({
                title: 'Actualizando divisas'
            });
            this.$swal.showLoading();
            let check = this.ischecked(data.id);
            this.$swal.fire({
                icon: "warning",
                title: check ? 'Deseas desactivar esta divisa?' : 'Deseas activar esta divisa?',
                showConfirmButton: true,
                confirmButtonText: "si",
                showCancelButton: true,
                cancelButtonText: "no"
            }).then((result) => {
                if (result.isConfirmed) {
                    if (check) {
                        self.desactivarDivisa(data);
                    } else {
                        self.activarDivisa(data);
                    }

                } else {
                    this.$swal.close();
                    self.verModal = true;
                }
            });
            console.log(check);

        },
        getDivisa() {
            this.$swal({
                title: 'Obteniendo divisas'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/currencies', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {

                if (response.status == 200) {
                    self.items = response.data.map(ind => {
                        return {
                            id: ind.id_currency,
                            nombre: ind.name,
                            sigla: ind.initials,
                            pais: ind.country
                        }
                    })

                    //self.divisasSelect = response.data.divisaEmpresa;
                    this.$swal.close();
                } else {
                    self.proveedores = [];
                }
            }).catch(function (error) {
                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });;
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
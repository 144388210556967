<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.bh-table-responsive input[type=checkbox] {
    width: 2rem;
    height: 1rem;
    opacity: 1;
    position: inherit;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="p-5 pt-2">
                <div class="py-4">
                    <!-- <h2 class="">Lista de conceptos para {{ clientName }}</h2> -->
                    <h2 class="">Lista de conceptos</h2>
                    <div class="text-end">
                        <a class="btn btn-success" @click="verModalAdd = true">Agregar conceptos</a>
                    </div>
                </div>
                <div v-if="conceptsTable.length == 0" class="text-center">
                    <h2 class="mt-5">No hay conceptos ingresadas</h2>
                </div>
                <div v-if="conceptsTable.length > 0">
                    <div class="mb-5">
                        <input v-model="search" type="text" class="form-input max-w-xs"
                            placeholder="Buscar conceptos..." />
                    </div>

                    <vue3-datatable :search="search" :rows="conceptsTable" :columns="columns" order :sortable="true">
                        <template #status="data">
                            <span>{{ data.value.is_activate == true ? 'Activado' : 'Desactivado' }}</span>
                        </template>
                        <template #created_at="data">
                            <span>{{ formattedDate(data.value.created_at) }}</span>
                        </template>

                        <template #actions="data">
                            <div class="form-switch">
                                <a class="btn" @click="editSupply(data.value.id_concept, data.value.name,data.value.is_activate)">
                                    <i class="bi bi-pencil px-2"></i>
                                </a>

                                <a v-if="data.value.is_activate == true" class="btn"
                                    @click="deleteSupply(data.value.id_concept)">
                                    <i class="bi bi-trash px-2"></i>
                                </a>
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
        <!-- Modal -->
        <div class="modal-mb" v-show="verModalAdd">
            <div class="modal-dialog bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-10" id="exampleModalLabel">Añadir conceptos</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="row">

                            <div class="col-md-12 mb-4 mt-4">
                                <label for="amount" class="form-label">Nombre</label>
                                <input type="text" class="form-control" :class="{ 'border-danger': modalForm.name.err }"
                                    id="amount" placeholder="" v-model="modalForm.name.value" @blur="handlerBlur">
                                <span v-if="modalForm.name.err" class="text-danger">Debe ingresar un concepto</span>
                            </div>
                            <div class="col-4 mb-4">
                                <label for="bank_type" class="form-label">Estado</label>
                                <select class="form-select" aria-label="Default select example"
                                    v-model="modalForm.status.value">
                                    <option :value=1>Activado
                                    </option>
                                    <option :value =0>Desactivado
                                    </option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary mx-2" v-if="!isEdit"
                            @click="addSupply()">Guardar</button>
                        <button type="button" class="btn btn-secondary mx-2" v-if="isEdit"
                            @click="updSupply()">Actualizar</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

export default {
    components: {
        Vue3Datatable,
        VueDatePicker,
        NavBar,
        BarTop,
        ref
    },
    data() {
        return {
            collapsed: true,
            verModalAdd: false,
            id_client_supply: 0,
            isEdit: false,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            clientName: '',
            search: '',
            id_concept: 0,
            items: [

            ],
            conceptsTable: [],
            modalForm: {
                name: {
                    value: '',
                    err: false
                },
                status: {
                    value: 1,
                    err: false
                },
            },
            clientSupply: [],
            columns: [
                { field: "created_at", title: "Fecha" },
                { field: "status", title: "Estado", filter: false },
                { field: "name", title: "Nombre", filter: false },
                { field: 'actions', title: '', sortable: false },

            ]
        }
    },
    mounted() {

        document.body.className = this.theme;
        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');
        if (parts.length == 4) {
            if (!isNaN(Number(parts[3]))) {

                this.edit_id = Number(parts[3]);
                this.getConcepts(this.edit_id);
            }
        }


    },
    computed: {

    },
    methods: {
        formattedDate(dateF) {
            const date = new Date(dateF);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Mes empieza en 0
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");

            return `${day}-${month}-${year} ${hours}:${minutes}`;
        },
        formatCurrency(value, currencyCode = 'CLP') {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        async getConcepts(idClient) {

            try {
                const response = await axios.get('/api/getClientConcept/' + idClient, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });

                this.conceptsTable = response.data;
                console.log(this.conceptsTable);
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },

        closeModal() {
            this.verModalAdd = false;
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        updSupply() {
            const self = this;

            let errFound = false;
            const validateField = (field) => {
                if (field.value === '' || field.value === 0 || !field.value) {
                    field.err = true;
                    field.msjErr = 'Este campo es obligatorio';
                    console.log(field)
                    errFound = true;
                } else {
                    field.err = false;
                    field.msjErr = '';
                }
            };
            validateField(this.modalForm.name);

            // Verificar si hay errores
            if (errFound) {
                self.verModalAdd = true;
                return false

            }
            self.verModalAdd = false;
            this.$swal.showLoading();
            const req = {
                'name': this.modalForm.name.value,
                'is_activate': this.modalForm.status.value,
                'id_client': this.edit_id,
            }

            axios.post(`/api/concept/${this.id_concept}?_method=PATCH`, req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {

                if (response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        title: "conceptos del cliente actualizada exitosamente",
                        showConfirmButton: true,
                        confirmButtonText: "ok",
                        showCancelButton: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getConcepts(this.edit_id);
                            self.modalForm.name.value = ''
                            self.isEdit = false
                        }
                    });


                } else {

                }
            })
        },
        addSupply() {
            const self = this;

            let errFound = false;
            const validateField = (field) => {
                if (field.value === '' || field.value === 0 || !field.value) {
                    field.err = true;
                    field.msjErr = 'Este campo es obligatorio';
                    console.log(field)
                    errFound = true;
                } else {
                    field.err = false;
                    field.msjErr = '';
                }
            };
            validateField(this.modalForm.name);

            // Verificar si hay errores
            if (errFound) {
                self.verModalAdd = true;
                return false

            }
            self.verModalAdd = false;
            this.$swal.showLoading();
            const req = {
                'name': this.modalForm.name.value,
                'is_activate': this.modalForm.status.value,
                'id_client': this.edit_id,
            }

            axios.post('/api/concept',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {

                    if (response.status == 201) {
                        this.$swal.fire({
                            icon: "success",
                            title: "concepto del cliente guardada exitosamente",
                            showConfirmButton: true,
                            confirmButtonText: "ok",
                            showCancelButton: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.getConcepts(this.edit_id);
                                self.modalForm.name.value = '';
                                self.modalForm.status.value = 1;
                                self.isEdit = false
                            }
                        });


                    } else {

                    }
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });
        },
        editSupply(id, name,is_activate) {
            const self = this;
            self.id_concept = id;
            self.modalForm.name.value = name;
            self.modalForm.status.value = is_activate;
            self.isEdit = true;
            self.verModalAdd = true;
        },
        deleteSupply(id_client_supply) {
            const self = this;
            this.$swal.fire({
                icon: "warning",
                title: "Estas seguro que deseas eliminar este concepto?",
                showConfirmButton: true,
                confirmButtonText: "si, eliminar",
                showCancelButton: true,
                cancelButtonText: "no"
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/api/concept/${id_client_supply}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        console.log(response)
                        if (response.status == 200) {
                            this.getConcepts(this.edit_id);
                            self.isEdit = false
                            this.$swal.fire({
                                icon: "success",
                                title: "Concepto eliminado con exito",
                            })
                        } else {

                        }
                    }).catch(function (error) {

                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        }
                    });
                }
            });
        },

    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
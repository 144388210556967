<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.bh-table-responsive input[type=checkbox] {
    width: 2rem;
    height: 1rem;
    opacity: 1;
    position: inherit;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="p-5 pt-2">
                <div class="py-4">
                    <h2 class="">Lista de clientes</h2>
                    <div class="text-end">
                        <a class="btn btn-success" href="clientes/create">Agregar cliente</a>
                    </div>
                </div>
                <div v-if="items.length == 0" class="text-center">
                    <h2 class="mt-5">No hay clientes ingresados</h2>
                </div>
                <div v-if="items.length > 0">
                    <div class="mb-5">
                        <input v-model="search" type="text" class="form-input max-w-xs"
                            placeholder="Buscar cliente..." />
                    </div>

                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'ID'">
                        <template #id_client="data">
                            <div v-bind:class="(data.value.status != 1) ? 'text-danger' : 'text-dark'">
                                {{ data.value.id_client }}
                            </div>
                        </template>

                        <template #name="data">
                            <div v-bind:class="(data.value.status != 1) ? 'text-danger' : 'text-dark'">
                                {{ data.value.name }}
                            </div>
                        </template>

                        <template #rut="data">
                            <div style=" white-space: nowrap;"
                                v-bind:class="(data.value.status != 1) ? 'text-danger' : 'text-dark'">
                                {{ data.value.rut }}
                            </div>
                        </template>

                        <template #email="data">
                            <div v-bind:class="(data.value.status != 1) ? 'text-danger' : 'text-dark'">
                                {{ data.value.email }}
                            </div>
                        </template>

                        <template #status="data">
                            <div v-bind:class="(data.value.status != 1) ? 'text-danger' : 'text-dark'">
                                <strong>{{ data.value.status == 1 ? 'HABILITADO' : 'DESHABILITADO' }}</strong>
                            </div>
                        </template>

                        <!-- Celda personalizada -->

                        <template #actions="data">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    Acciones
                                </button>
                                <ul class="dropdown-menu">
                                    <li @click="editarClient(data.value.id_client)"><a class="dropdown-item" href="#">
                                            <i class="bi bi-pencil px-2"></i>Editar</a></li>
                                    <li @click="clientSupplies(data.value.id_client)"><a class="dropdown-item" href="#">
                                            <i class="bi bi-cash px-2"></i>Provisiones</a></li>
                                    <li @click="clientBank(data.value.id_client)"><a class="dropdown-item" href="#">
                                            <i class="bi bi-bank px-2"></i>Cuentas Banco</a></li>
                                    <li @click="clientConcepts(data.value.id_client)"><a class="dropdown-item" href="#">
                                            <i class="bi bi-file px-2"></i>Conceptos</a></li>
                                    <li v-if="data.value.status == 1" @click="disableClient(data.value.id_client)"><a
                                            class="dropdown-item" href="#">
                                            <i class="bi bi-toggle2-off px-2"></i>Deshabilitar</a></li>
                                    <li v-else @click="enableClient(data.value.id_client)"><a class="dropdown-item"
                                            href="#">
                                            <i class="bi bi-toggle2-on px-2"></i>Habilitar</a></li>
                                   
                                </ul>
                            </div>

                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
         <!-- Ver -->
         <div class="modal-mb" v-if="verModal">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Estado cuenta Cliente</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal2()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <vue3-datatable :search="search" :rows="unpaidAcc" :columns="columns2" order :sortable="true"
                        :sortColumn="'MONTO'">
                        </vue3-datatable>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal2()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";

import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
export default {
    components: {
        Vue3Datatable,
        NavBar,
        BarTop,
        ref
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            search: '',
            items: [

            ],
            unpaidAcc:[],
            columns2:[
                { field: "CLIENTE", title: "Cliente" },
                { field: "RUT", title: "RUT" },
                { field: "FACTURA", title: "Factura" },
                { field: "N_COBRO", title: "N Cobro" },
                { field: "MONTO", title: "Monto" },
                { field: "ABONO", title: "Abono" },
                { field: "SALDO", title: "Saldo" },

            ],
            verModal:false,
            clientesSelect: [],
            columns: [
                { field: "id_client", title: "ID", filter: false },
                { field: "name", title: "Nombre" },
                { field: "rut", title: "RUT" },
                { field: "email", title: "Correo" },
                { field: "status", title: "Estado" },
                { field: 'actions', title: '', sortable: false },

            ]
        }
    },
    mounted() {
        this.getClients()
        document.body.className = this.theme;
    },
    computed: {

    },
    methods: {
        closeModal2(){
            this.verModal = false;
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        editarClient(id_client) {
            window.location.href = 'clientes/create/' + id_client;
        },

        formatRut(rut, withDots) {
            let parts = rut.split('-');
            let nums = parts[0].replaceAll('.', '');
            if (withDots) {
                let rutnum = new Intl.NumberFormat('es-CL').format(nums);
                return rutnum + "-" + parts[1];
            } else {
                return nums + "-" + parts[1];
            }
        },
        clientConcepts(id_client) {
            window.location.href = 'clientes/concept/' + id_client;
        },
        clientBank(id_client) {
            window.location.href = 'clientes/bank/' + id_client;
        },
        showUnpaid(id_client){
            const self = this;
            this.$swal({
                title: 'Obteniendo clientes'
            });
            this.$swal.showLoading();
            let req = {
                id_client: id_client
            }
            self.verModal = true;
            axios.post('/api/balanceUnpaid',req ,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    console.log(response);
                    this.unpaidAcc = response.data.data;
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.items = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });
        },
        clientSupplies(id_client) {
            window.location.href = 'clientes/supplies/' + id_client;
        },
        changeClientStatus(id_client, id_status, msg, msg_error) {
            const self = this;

            let urlpost = '/api/clients/' + id_client + '?_method=PATCH' // para editar

            axios.post(urlpost,
                {
                    status: id_status,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then(response => {
                    //console.log(response.data)
                    self.$swal.close();
                    if (response.status == 200) {
                        ;
                        self.$swal.fire({
                            title: msg,
                            icon: "info",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Ok"
                        }).then((result) => {
                            this.getClients();
                        });

                    } else {
                        console.log(response.data);

                        self.$swal.fire({
                            icon: "error",
                            title: msg_error,
                            text: "Comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                }).catch(function (error) {
                    self.$swal.close();

                    if (error.response.statusText) {
                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        } else {
                            //console.log(error.response.statusText);
                            console.log(error.response.data);
                            //console.log(error.response.data.errors);

                            self.$swal.fire({
                                icon: "error",
                                title: "Error",
                                text: "Problema modificando cliente detectado, comuníquese con soporte.",
                                showConfirmButton: false,
                                showCancelButton: false
                            });
                        }
                    } else {
                        console.log(error);
                        self.$swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Problema interno detectado, comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });

                    }
                });
        },

        disableClient(id_client) {
            const self = this;

            this.$swal.fire({
                title: "¿Está seguro que desea deshabilitar este cliente?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, deshabilitar"
            }).then((result) => {
                if (result.isConfirmed) {
                    self.changeClientStatus(id_client,
                        2,
                        "cliente deshabilitado exitosamente",
                        "error al deshabilitar cliente");
                }
            });
        },


        enableClient(id_client) {
            const self = this;

            this.$swal.fire({
                title: "¿Está seguro que desea habilitar este cliente?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, habilitar"
            }).then((result) => {
                if (result.isConfirmed) {
                    self.changeClientStatus(id_client,
                        1,
                        "cliente habilitado exitosamente",
                        "error al habilitar cliente");
                }
            });
        },

        getClients() {
            this.$swal({
                title: 'Obteniendo clientes'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.items = response.data.map((client) => {
                        client.rut = self.formatRut(client.rut, true)
                        return client
                    });

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.items = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="">Despachos</h2>
                    <p>Listar todos los despachos.</p>
                    <div class="text-end">
                        <a class="btn btn-success" href="despachos/crear">Agregar despachos</a>
                    </div>
                </div>
                <p>Filtros</p>
                <div class="m-3 row">
                    <div class="col-md-2 mb-4">
                        <label for="rut" class="form-label">RUT cliente</label>
                        <input type="text" class="form-control" :class="{ 'border-danger': dataForm.rut.err }" id="rut"
                            placeholder="" v-model="dataForm.rut.value">
                        <span v-if="dataForm.rut.err" class="text-danger">Debe ingresar un RUT</span>
                    </div>
                    <div class="col-md-2 mb-4">
                        <label for="boarding_number" class="form-label">Número de embarque</label>
                        <input type="text" class="form-control"
                            :class="{ 'border-danger': dataForm.boarding_number.err }" id="boarding_number"
                            placeholder="" v-model="dataForm.boarding_number.value">
                        <span v-if="dataForm.boarding_number.err" class="text-danger">Debe ingresar un número de
                            despacho</span>
                    </div>
                    <div class="col-md-2 mb-4">
                        <label for="booking_number" class="form-label">Número de reserva</label>
                        <input type="text" class="form-control"
                            :class="{ 'border-danger': dataForm.booking_number.err }" id="booking_number" placeholder=""
                            v-model="dataForm.booking_number.value">
                    </div>
                    <div class="col-md-2 mb-4">
                        <label for="shipping_number" class="form-label">Número de despacho</label>
                        <input type="text" class="form-control"
                            :class="{ 'border-danger': dataForm.shipping_number.err }" id="shipping_number"
                            placeholder="" v-model="dataForm.shipping_number.value">
                        <span v-if="dataForm.shipping_number.err" class="text-danger">Debe ingresar un número de
                            documento</span>
                    </div>

                    <div class="col-md-2 mt-4">
                        <div class="text-end">
                            <a class="btn btn-success" @click="filter()">Filtrar</a>
                        </div>
                    </div>
                </div>
                <div>
                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'date'">
                        <template #amount="data">
                            <span>{{ data.value.amount > 0 ? formatCurrency(data.value.amount, data.value.initials) : 0
                                }}</span>
                        </template>
                        <template #value_payment="data">
                            <span>{{ data.value.value_payment > 0 ? formatCurrency(data.value.value_payment,
            data.value.value_payment_initials) : 0 }}</span>
                        </template>
                        <template #unpaid_amount="data">
                            <span>{{ data.value.unpaid_amount > 0 ? formatCurrency(data.value.unpaid_amount,
            data.value.value_unpaid_initials) : 0 }}</span>
                        </template>
                        <template #rut="row">
                            <td style="text-align: end; white-space: nowrap;">{{ row.value.rut }}</td>
                        </template>
                        <template #date_shipping="row">
                            <td style="text-align: end; white-space: nowrap;">{{ row.value.date_shipping }}</td>
                        </template>
                        <template #accion="data">
                            <div class="text-center">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Acciones
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li @click="editPackage(data.value.id_shipping)">
                                            <a class="dropdown-item" href="#"> <i
                                                    class="bi bi-box px-2"></i>Paquetes</a>
                                        </li>
                                        <li @click="editShipping(data.value.id_shipping)">
                                            <a class="dropdown-item" href="#"> <i
                                                    class="bi bi-pencil px-2"></i>Editar</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
    </div>
</template>
<script>

import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

export default {
    components: {

        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            items: [

            ],
            dataForm: {
                rut: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                shipping_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                boarding_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                booking_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },

            },
            columns: [
                { field: "shipping_number", title: "Número Despacho" },
                { field: "date_shipping", title: "Fecha" },
                { field: "week", title: "Semana" },
                { field: "rut", title: "Rut cliente" },
                { field: "boarding_number", title: "Número de embarque" },
                { field: "booking_number", title: "Número de reserva" },
                { field: "type_operation", title: "Tipo operación" },
                { field: "consignee", title: "Despachador" },
                { field: "aduana", title: "Aduana" },
                { field: "vessel", title: "Vessel" },
                { field: "consignee", title: "Consignatario" },
                { field: "bailment", title: "Comodato" },
                { field: "port_departure_shipment", title: "Puerto de embarque" },
                { field: "country_destination", title: "País destino" },
                { field: "accion", title: "" },
            ]
        }
    },
    mounted() {
        this.getAR()
        document.body.className = this.theme;

    },
    computed: {

    },
    methods: {
        filter() {
            const self = this;

            let params = {
                page: 1,
                per_page: 10, rut: this.dataForm.rut.value, nroBoarding: this.dataForm.boarding_number.value,
                nroBooking: this.dataForm.booking_number.value, nroDespacho: this.dataForm.shipping_number.value,
            }
            axios.post('/api/getShippings', params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });



        },
        formatCurrency(value, currencyCode = 'CLP') {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        updateCollapsed(value) {

            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {

            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;

        },
        editPackage(id) {
            window.location.href = '/despachos/package/' + id;
        },
        editShipping(id) {
            window.location.href = '/despachos/edit/' + id;
        },
        getAR() {
            this.$swal({
                title: 'Obteniendo cuentas por cobrar'
            });
            this.$swal.showLoading();
            const self = this;
            let params = {
                page: 1,
                per_page: 10, rut: this.dataForm.rut.value, nroBoarding: this.dataForm.boarding_number.value,
                nroBooking: this.dataForm.booking_number.value, nroDespacho: this.dataForm.shipping_number.value,
            }
            axios.post('/api/getShippings', params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });

        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
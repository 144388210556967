<style scoped>
.tabs {
    display: flex;

    margin-bottom: 0px;
}

button {
    padding: 10px 15px;
    border: none;
    background: #ddd;
    cursor: pointer;

}

button.active {
    background: #007bff;
    color: white;
}

.tab-content {
    padding: 10px;
    border: 1px solid #ddd;

}

.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="">Estado de cuenta</h2>
                    <p>Listar todos los estados de cuenta de los clientes ingresados en el sistema.</p>

                </div>
                <p>Filtros</p>
                <div class="m-3 row">
                    <div class="col-4 mb-4">
                        <label for="client" class="form-label">Seleccionar cliente</label>
                        <select class="form-select" aria-label="Default select example" v-model="dataForm.client.value">
                            <option :value="''">
                                Seleccione un cliente
                            </option>
                            <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                            </option>
                        </select>
                        <span v-if="dataForm.client.err" class="text-danger">Debe seleccionar un cliente</span>
                    </div>
                    <div class="col-md-2 mt-4">
                        <div class="text-end">
                            <a class="btn btn-success" @click="changeClient()">Filtrar</a>
                        </div>
                    </div>
                </div>
                <div class="tabs" v-if="showtable != 0">
                    <button v-for="tab in tabs" :key="tab.id" :class="{ active: activeTab === tab.id }"
                        @click="filter(tab.id)">
                        {{ tab.name }}
                    </button>
                </div>

                <div class="tab-content" v-if="showtable != 0">
                    <div>
                        <div>
                            <h5 class="text-black">Monto impago total: {{ totalUnpaidAmount() }}</h5>

                        </div>
                        <div>
                            <vue3-datatable :search="search" :rows="unpaidAcc" :columns="columns2" order
                                :sortable="true" :sortColumn="'MONTO'">
                                <template #MONTO="data">
                                    <span>{{ data.value.MONTO > 0 ? formatCurrency(data.value.MONTO,
            data.value.initials) : 0 }}</span>
                                </template>
                                <template #ABONO="data">
                                    <span>{{ data.value.MONTO > 0 ? formatCurrency(data.value.ABONO,
            data.value.initials) : 0 }}</span>
                                </template>
                                <template #SALDO="data">
                                    <span>{{ data.value.SALDO > 0 ? formatCurrency(data.value.SALDO,
                                        data.value.initials) : 0 }}</span>
                                </template>
                            </vue3-datatable>
                        </div>
                    </div>

                </div>

            </div>
        </main>

    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

export default {
    components: {
        VueDatePicker,
        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            verModal: false,
            showtable: 0,
            tabs: [
                
            ],
            activeTab: 0,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },

            unpaidAcc: [],
            columns2: [
                { field: "EMISION", title: "Emisión" },
                { field: "FACTURA", title: "Factura" },
                { field: "N_COBRO", title: "Nota cobro" },
                { field: "MONTO", title: "Monto" },
                { field: "ABONO", title: "Abono" },
                { field: "SALDO", title: "Saldo" },

            ],
            dataForm: {
                client: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
            },
            clients: [],

        }
    },
    mounted() {
        this.getClients();
        document.body.className = this.theme;

    },
    computed: {

    },
    methods: {
        formatCurrency(value, currencyCode = 'CLP') {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        totalUnpaidAmount() {
            // Calcula el total de los valores de SALDO
            let total = this.unpaidAcc
                .reduce((total, item) => {
                    // Asegúrate de que el campo SALDO es un número válido
                    const saldo = parseFloat(item.SALDO) || 0;
                    return total + saldo;
                }, 0);

            console.log(total);

            return this.formatNumber(total)// Opcional: Formatear el resultado con 2 decimales
        },
        formatNumber(value) {
            return value.toLocaleString('es-ES'); // Formato con separadores para español
        },
        async changeClient(){
            await this.getCurrencyUnpaid(this.dataForm.client.value);
          
        },
        async filter(id_tab) {
            const self = this;
            
            self.showtable = 1;
           
            self.activeTab = id_tab;
            const selectedCurrency = this.tabs.find((tab) => tab.id === id_tab).id_currency;
            let req = {
                page: 1,
                id_currency: selectedCurrency,
                per_page: 10, id_client: this.dataForm.client.value
            }
            axios.post('/api/balanceUnpaid', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    console.log(response);
                    this.unpaidAcc = response.data.data;
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.items = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });
        },
        async getCurrencyUnpaid(id_client) {
            let req = {
                id_client: id_client
            }
            axios.post('/api/getUnpaidSummary', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    this.tabs = response.data.divisas.map((currency, index) => ({
                        id: index + 1, // Genera un ID basado en la posición
                        name: `${currency.name} (${currency.initials})`, // Combina el nombre e iniciales
                        id_currency: currency.id_currency, // Conserva el ID original
                    }));
                    this.activeTab = this.tabs[0].id;
                    this.filter( this.activeTab)
                } else {

                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });
        },
        updateCollapsed(value) {

            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {

            this.pinned = value; // Actualiza el estado
        },
        showBill(data) {
            this.verModal = true;
            this.billNote = data;
        },
        getClients() {
            this.$swal({
                title: 'Obteniendo clientes'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.clients = response.data;

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.items = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
<style>
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

.h-custom {
  height: calc(100% - 73px);
}

.body {
  background-repeat: no-repeat;
  padding: 0;
  color: white;
  background: #000000;
  background-image: url(./../../img/bg_Frontera.jpg);
  background-position: bottom;
  background-size: cover;
  font-family: 'Montserrat', sans-serif;
}

@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
</style>
<template>
  <section class="vh-100 body">
    <div>
      <img src="../../img/logo-blanco.png" style="width: 10%;" class="m-3" alt="">
    </div>
    <div class="container-fluid h-custom">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-md-9 col-lg-6 col-xl-5" style="    margin-bottom: 18rem;">
          <!--img
            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
            class="img-fluid"
            alt="Sample image"
          /> -->
          <div class="d-flex text-black">
            <i class="bi bi-rocket-takeoff px-3" style="font-size: 1.7rem;"></i>
            <h3 style="font-weight: bold;" class="">FRONTERA</h3>
          </div>
         
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1 text-black">
          <form>
            <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
              <h2 class="fw-normal mb-4 me-3"><b>Iniciar Sesión</b></h2>
            </div>

            <!-- Email input -->
            <div class="form-outline mb-4">
              <label class="form-label" for="form3Example3">Correo</label>
              <input type="text" id="form3Example3" v-model="formData.email.value" @blur="handlerBlur"
                class="form-control " :class="{ 'border-danger': formData.email.err }" placeholder="Usuario" />
              <span v-if="formData.email.err" class="fill mt-2 text-danger">{{ formData.email.msjErr }}</span>
            </div>

            <!-- Password input -->
            <div class="form-outline mb-3">
              <label class="form-label" for="form3Example4">Contraseña</label>
              <input v-model="formData.pass.value" @blur="handlerBlur2" type="password" id="form3Example4"
                class="form-control " :class="{ 'border-danger': formData.pass.err }" placeholder="Contraseña" />
              <span v-if="formData.pass.err" class="fill mt-2 text-danger">{{ formData.pass.msjErr }}</span>
            </div>

            <!-- div class="d-flex justify-content-between align-items-center">
              < Checkbox >
              <div class="form-check mb-0">
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  value=""
                  id="form2Example3"
                />
                <label class="form-check-label" for="form2Example3">
                  Recordar cuenta
                </label>
              </div>
              <a href="#!" class="text-body">Olvidaste tu contraseña?</a>
            </div-->

            <div class="text-center text-lg-start mt-4 pt-2">
              <button type="button" class="btn btn-lg text-white"
                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;" @click="login()">
                Ingresar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--div
      class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary"
    >
      <Copyright>
      <div class="text-white mb-3 mb-md-0">Terminos & condiciones</div>
      < Copyright >
    </div -->
  </section>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      formData: {
        sending: false,
        email: {
          value: '',
          err: false,
          msjErr: ''
        },
        pass: {
          value: '',
          err: false,
          msjErr: ''
        }
      }

    }
  },
  mounted() {
  },
  methods: {
    login() {
      const self = this;
      self.formData.sending = true;
      if (self.formData.email.value == '') {
        self.formData.email.msjErr = 'Ingrese un correo valido'
        self.formData.email.err = true;
        return false;
      }
      if (self.formData.pass.value == '') {
        self.formData.pass.msjErr = 'Ingrese una contraseña'
        self.formData.pass.err = true;
        return false;
      }
      const req = { email: self.formData.email.value, password: self.formData.pass.value }
      axios.post('/api/auth/login', req).then(response => {
       
        if (response.status == 200) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
          const accessToken = response.data.token;
          if(response.data.user.company.skin){
            localStorage.setItem('theme', response.data.user.company.skin);
          }
          // Guardar el token en el localStorage
          localStorage.setItem('accessToken', accessToken);
          window.location.href = 'alerts'
         
        } else {
          this.$swal.fire({
            icon: "warning",
            title: "Error!",
            text: "Credenciales invalidas.",
            showConfirmButton: false,
            showCancelButton: false
          }).then((result) => {

          });
        }
      }, error => {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: "Problema con nuestro servidor, intentelo mas tarde.",
          showConfirmButton: false,
          showCancelButton: false
        });
      });
    }, handlerBlur() {
      const self = this;
      if (self.formData.email.value == '') {
        self.formData.email.msjErr = 'Ingrese un correo valido'
        self.formData.email.err = true;
      } else {
        self.formData.email.err = false;
      }
    },
    handlerBlur2() {
      const self = this;
      if (self.formData.pass.value == '') {
        self.formData.pass.msjErr = 'Ingrese una contraseña'
        self.formData.pass.err = true;
      } else {
        self.formData.pass.err = false;
      }
    }
  },
};
</script>
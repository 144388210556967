<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.bh-table-responsive input[type=checkbox] {
    width: 2rem;
    height: 1rem;
    opacity: 1;
    position: inherit;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="p-5 pt-2">
                <div class="py-4">
                    <!-- <h2 class="">Lista de cuenta bancarias para {{ clientName }}</h2> -->
                    <h2 class="">Lista de cuentas bancarias</h2>
                    <div class="text-end">
                        <a class="btn btn-success" @click="verModalAdd = true; isEdit = false">Agregar cuenta
                            bancaria</a>
                    </div>
                </div>
                <div v-if="clientBank.length == 0" class="text-center">
                    <h2 class="mt-5">No hay cuentas ingresadas</h2>
                </div>
                <div v-if="clientBank.length > 0">
                    <div class="mb-5">
                        <input v-model="search" type="text" class="form-input max-w-xs"
                            placeholder="Buscar cuenta bancarias..." />
                    </div>

                    <vue3-datatable :search="search" :rows="clientBank" :columns="columns" order :sortable="true">
                        <template #item="data">
                            <span>{{ transformItem(data.value.client_supply_concept) }}</span>
                        </template>
                        <template #status="data">
                            <span>{{ data.value.is_activate == true ? 'Activado' : 'Desactivado' }}</span>
                        </template>
                        <template #amount="data">
                            <span>{{ data.value.amount > 0 ? formatCurrency(data.value.amount,
            data.value.currency.initials) : 0 }}</span>
                        </template>

                        <template #actions="data">
                            <div class="form-switch">
                                <a class="btn"
                                    @click="editSupply(data.value.id_client_bank, data.value.id_bank, data.value.id_client_bank, data.value.account_number, data.value.is_activate)">
                                    <i class="bi bi-pencil px-2"></i>
                                </a>
                                <a v-if="data.value.is_activate == true" class="btn"
                                    @click="deleteSupply(data.value.id_client_bank)">
                                    <i class="bi bi-trash px-2"></i>
                                </a>
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
        <!-- Modal -->
        <div class="modal-mb" v-show="verModalAdd">
            <div class="modal-dialog bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-10" id="exampleModalLabel">Añadir cuenta bancaria</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="row">
                            <div class="col-4 mb-4">
                                <label for="bank" class="form-label">Banco de origen</label>
                                <select class="form-select" aria-label="Default select example"
                                    v-model="modalForm.bank.value">
                                    <option v-for="bank in banks" :value="bank.id_bank">{{ bank.name }}</option>
                                </select>
                            </div>
                            <div class="col-4 mb-4">
                                <label for="bank_type" class="form-label">Tipo de cuenta</label>
                                <select class="form-select" aria-label="Default select example"
                                    v-model="modalForm.bank_type.value">
                                    <option v-for="bank_type in bank_types" :value="bank_type.id_bank_account_type">{{
            bank_type.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="account_number" class="form-label">Numero de cuenta</label>
                                <input type="number" class="form-control"
                                    :class="{ 'border-danger': modalForm.account_number.err }" id="account_number"
                                    placeholder="" v-model="modalForm.account_number.value" @blur="handlerBlur">
                                <span v-if="modalForm.account_number.err" class="text-danger">Debe ingresar un numero de
                                    cuenta</span>
                            </div>
                            <div class="col-4 mb-4">
                                <label for="bank_type" class="form-label">Estado</label>
                                <select class="form-select" aria-label="Default select example"
                                    v-model="modalForm.status.value">
                                    <option :value=1>Activado
                                    </option>
                                    <option :value=0>Desactivado
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary mx-2" v-if="!isEdit"
                            @click="addSupply()">Guardar</button>
                        <button type="button" class="btn btn-secondary mx-2" v-if="isEdit"
                            @click="updSupply()">Actualizar</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

export default {
    components: {
        Vue3Datatable,
        VueDatePicker,
        NavBar,
        BarTop,
        ref
    },
    data() {
        return {
            collapsed: true,
            verModalAdd: false,
            id_client_supply: 0,
            isEdit: false,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            clientName: '',
            search: '',
            items: [

            ],
            clientBank: [],
            modalForm: {
                account_number: {
                    value: '',
                    err: false
                },

                bank: {
                    value: 1,
                    err: false
                },
                bank_type: {
                    value: 1,
                    err: false
                },
                status: {
                    value: 1,
                    err: false
                },
            },
            banks: [],
            bank_types: [],
            id_clientBank: 0,
            columns: [

                { field: "bank_name", title: "Banco", filter: false },
                { field: "status", title: "Estado", filter: false },
                { field: "account_type_name", title: "Tipo cuenta", filter: false },
                { field: "account_number", title: "Numero de cuenta" },
                { field: 'actions', title: '', sortable: false },

            ]
        }
    },
    mounted() {

        document.body.className = this.theme;
        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');
        if (parts.length == 4) {
            if (!isNaN(Number(parts[3]))) {
                this.edit_id = Number(parts[3]);
                this.getBank(this.edit_id);
            }
        }
        this.getBanks();
        this.getBanksType();
    },
    computed: {

    },
    methods: {

        async getBanks() {
            try {
                const response = await axios.get('/api/banks', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.banks = response.data;
            } catch (error) {
                console.error('Error fetching banks:', error);
            }
        },
        async getBanksType() {
            try {
                const response = await axios.get('/api/bank-account-types', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.bank_types = response.data;
            } catch (error) {
                console.error('Error fetching banks:', error);
            }
        },
        formatCurrency(value, currencyCode = 'CLP') {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        async getBank(id_client) {

            try {
                const response = await axios.get('/api/getClientBank/' + id_client, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                console.log(response.data);
                this.clientBank = response.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        async getCurrencies() {
            try {
                const response = await axios.get('/api/currencies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.currencies = response.data;
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        },
        transformItem(item) {
            // Úne el array en una cadena separada por comas y espacios

            return item.map(concept => concept.concept.name)
                .join(', ');;
        },
        closeModal() {
            this.verModalAdd = false;
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        updSupply() {
            const self = this;

            let errFound = false;
            const validateField = (field) => {
                if (field.value === '' || field.value === 0 || !field.value) {
                    field.err = true;
                    field.msjErr = 'Este campo es obligatorio';
                    console.log(field)
                    errFound = true;
                } else {
                    field.err = false;
                    field.msjErr = '';
                }
            };
            validateField(this.modalForm.account_number);

            // Verificar si hay errores
            if (errFound) {
                self.verModalAdd = true;
                return false

            }
            self.verModalAdd = false;
            this.$swal.showLoading();
            const req = {
                'id_bank': this.modalForm.bank.value,
                'id_client': this.edit_id,
                'is_activate': this.modalForm.status.value,
                'id_account_type': this.modalForm.bank_type.value,
                'account_number': this.modalForm.account_number.value
            }

            axios.post(`/api/client-banks/${this.id_clientBank}?_method=PATCH`, req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {

                if (response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        title: "cuenta bancarias del cliente actualizada exitosamente",
                        showConfirmButton: true,
                        confirmButtonText: "ok",
                        showCancelButton: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getBank(this.edit_id);
                            self.modalForm.bank.value = 1
                            self.modalForm.bank_type.value = 1
                            self.modalForm.account_number.value = ''
                            self.isEdit = false
                        }
                    });


                } else {

                }
            })
        },
        addSupply() {
            const self = this;

            let errFound = false;
            const validateField = (field) => {
                if (field.value === '' || field.value === 0 || !field.value) {
                    field.err = true;
                    field.msjErr = 'Este campo es obligatorio';
                    console.log(field)
                    errFound = true;
                } else {
                    field.err = false;
                    field.msjErr = '';
                }
            };
            validateField(this.modalForm.account_number);

            // Verificar si hay errores
            if (errFound) {
                self.verModalAdd = true;
                return false

            }
            self.verModalAdd = false;
            this.$swal.showLoading();
            const req = {
                'id_bank': this.modalForm.bank.value,
                'id_client': this.edit_id,
                'is_activate': this.modalForm.status.value,
                'id_account_type': this.modalForm.bank_type.value,
                'account_number': this.modalForm.account_number.value
            }

            axios.post('/api/client-banks',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    console.log(response);
                    if (response.status == 201) {
                        this.$swal.fire({
                            icon: "success",
                            title: "cuenta bancarias del cliente guardada exitosamente",
                            showConfirmButton: true,
                            confirmButtonText: "ok",
                            showCancelButton: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.getBank(this.edit_id);
                                self.modalForm.bank.value = 1
                                self.modalForm.bank_type.value = 1
                                self.modalForm.account_number.value = ''
                            }
                        });


                    } else {

                    }
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });
        },
        editSupply(id, bank, account, number, status) {
            const self = this;
            self.id_clientBank = id;
            self.modalForm.bank.value = bank;
            self.modalForm.bank_type.value = account;
            self.modalForm.status.value = status;
            self.modalForm.account_number.value = number;
            self.isEdit = true;
            self.verModalAdd = true;
        },
        deleteSupply(id_client_supply) {
            const self = this;
            this.$swal.fire({
                icon: "warning",
                title: "Estas seguro que deseas eliminar esta cuenta?",
                showConfirmButton: true,
                confirmButtonText: "si, eliminar",
                showCancelButton: true,
                cancelButtonText: "no"
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/api/client-banks/${id_client_supply}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        console.log(response)
                        if (response.status == 200) {
                            this.getBank(this.edit_id);

                            this.$swal.fire({
                                icon: "success",
                                title: "Cuenta bancaria eliminada con exito",
                            })
                        } else {

                        }
                    }).catch(function (error) {

                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        }
                    });
                }
            });
        },

    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">

        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3"><b>Asociar paquete al despacho #{{ id_shipping
                                }}</b></h2>

                        <p>Permite asociar uno o más paquetes a un despacho.
                        </p>
                    </div>
                    <div>
                        <div class="text-end m-4">
                            <button type="button" class="btn btn-primary" @click="showAddPackage()">Agregar
                                paquete</button>
                        </div>
                        <div v-if="packages.length == 0" class="text-center">
                            <h2 class="mt-5">No hay paquetes asociados</h2>
                        </div>
                        <div v-if="packages.length > 0">
                            <vue3-datatable :search="search" :rows="packages" :columns="columns" order
                                :sortable="false">
                                <template #action="data">

                                    <div class="text-center">
                                        <div class="dropdown">
                                            <button class="btn btn-secondary dropdown-toggle" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                Acciones
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li @click="editPackage(data.value.id_shipping_package, false)"><a
                                                        class="dropdown-item" href="#"> <i
                                                            class="bi bi-eye px-2"></i>Ver</a></li>
                                                <li @click="editPackage(data.value.id_shipping_package, true)"><a
                                                        class="dropdown-item" href="#"> <i
                                                            class="bi bi-pencil px-2"></i>Editar</a></li>
                                                <li @click="eliminarPaquete(data.value.id_shipping_package)"><a
                                                        class="dropdown-item" href="#"> <i
                                                            class="bi bi-trash px-2"></i>Eliminar</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                </template>
                            </vue3-datatable>
                        </div>
                        <div class="text-center text-lg-end mt-4 pt-2">

                            <button type="button" class="btn btn-md text-white bg-danger"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="backPage()">
                                Volver
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <!-- Modal -->
        <div class="modal-mb" v-show="verModal">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-10" id="exampleModalLabel" v-if="agregar">Crear paquete
                        </h5>
                        <h5 class="modal-title text-black col-10" id="exampleModalLabel" v-else-if="esEditable">Editar
                        </h5>
                        <h5 class="modal-title text-black col-10" id="exampleModalLabel" v-else>Detalle:</h5>

                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class=" row">
                            <div class="col-md-2 mb-4 mt-4">
                                <label for="package_number" class="form-label">Número de paquete</label>
                                <input :disabled="!esEditable" type="text" class="form-control"
                                    :class="{ 'border-danger': modalForm.package_number.err }" id="package_number"
                                    placeholder="" v-model="modalForm.package_number.value" @blur="handlerBlur">
                                <span v-if="modalForm.package_number.err" class="text-danger">Debe ingresar un
                                    número de paquete</span>
                            </div>
                            <div class="col-md-3 mb-4 mt-4">
                                <label for="package_code" class="form-label">Código de paquete</label>
                                <input :disabled="!esEditable" type="text" class="form-control"
                                    :class="{ 'border-danger': modalForm.package_code.err }" id="package_code"
                                    placeholder="" v-model="modalForm.package_code.value" @blur="handlerBlur">
                                <span v-if="modalForm.package_code.err" class="text-danger">Debe ingresar un
                                    código de paquete</span>
                            </div>
                            <div class="col-md-3 mb-4 mt-4">
                                <label for="package_type" class="form-label">Tipo de paquete</label>
                                <input :disabled="!esEditable" type="text" class="form-control"
                                    :class="{ 'border-danger': modalForm.package_type.err }" id="package_type"
                                    placeholder="" v-model="modalForm.package_type.value" @blur="handlerBlur">
                                <span v-if="modalForm.package_type.err" class="text-danger">Debe ingresar un
                                    tipo</span>
                            </div>

                            <div class="col-md-3 mb-4 mt-4">
                                <label for="count" class="form-label">Cantidad</label>
                                <input :disabled="!esEditable" type="number" class="form-control"
                                    :class="{ 'border-danger': modalForm.count.err }" id="count" placeholder=""
                                    v-model="modalForm.count.value" @blur="handlerBlur">
                                <span v-if="modalForm.count.err" class="text-danger">Debe ingresar una
                                    cantidad</span>
                            </div>
                            <div class="col-md-3 mb-4 mt-4">
                                <label for="identification" class="form-label">Identificación</label>
                                <input :disabled="!esEditable" type="text" class="form-control"
                                    :class="{ 'border-danger': modalForm.identification.err }" id="identification"
                                    placeholder="" v-model="modalForm.identification.value" @blur="handlerBlur">
                                <span v-if="modalForm.identification.err" class="text-danger">Debe ingresar una
                                    identificación</span>
                            </div>
                            <div class="col-md-3 mb-4 mt-4">
                                <label for="sub_continent" class="form-label">Subcontinente</label>
                                <input :disabled="!esEditable" type="text" class="form-control"
                                    :class="{ 'border-danger': modalForm.sub_continent.err }" id="sub_continent"
                                    placeholder="" v-model="modalForm.sub_continent.value" @blur="handlerBlur">
                                <span v-if="modalForm.sub_continent.err" class="text-danger">Debe ingresar un
                                    subcontinente</span>
                            </div>
                            <br>
                            <h6 class="text-black">Administración de ítems dentro del paquete</h6>
                            <div class="row g-1 px-4" v-for="(prod, ind) in modalForm.items">
                                <div class="col-sm-2">
                                    <label for="count" class="form-label">Cantidad</label>
                                    <input :disabled="!esEditable" type="number" class="form-control" id="count"
                                        v-model="prod.count.value">

                                </div>
                                <div class="col-sm-2">
                                    <label for="number_item" class="form-label">Número de ítem</label>
                                    <input :disabled="!esEditable" type="number" class="form-control" id="number_item"
                                        v-model="prod.number_item.value">

                                </div>
                                <div class="col-sm-3">
                                    <label for="name_item" class="form-label">Nombre de ítem</label>
                                    <input :disabled="!esEditable" type="text" class="form-control" id="name_item"
                                        v-model="prod.name_item.value">
                                </div>
                                <div class="col-sm-2">
                                    <label for="weight_item" class="form-label">Peso bruto</label>
                                    <input :disabled="!esEditable" type="number" class="form-control" id="weight_item"
                                        v-model="prod.weight_item.value">

                                </div>
                                <div class="col-sm-2">
                                    <label for="value_fob" class="form-label">Valor FOB</label>
                                    <input :disabled="!esEditable" type="number" class="form-control" id="value_fob"
                                        v-model="prod.value_fob.value">

                                </div>
                                <div class="col-sm-1 py-1">

                                    <i class="bi bi-x-circle-fill text-danger h6" style="margin-top: 2rem;"
                                        v-if="modalForm.items.length > 1" @click="deleteItem(ind)">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-4">
                      
                        <button type="button" v-if="esEditable" class="btn btn-primary" @click="savePackage()">
                            {{ agregar ? "Crear paquete" : "Actualizar paquete" }}
                        </button>
                        <button type="button" class="btn btn-outline-danger mx-3" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import { readonly } from 'vue';


export default {
    components: {
        VueDatePicker,
        NavBar,
        BarTop,
        Vue3Datatable
    },
    data() {
        return {
            collapsed: true,
            id_shipping: 0,
            modalForm: {
                package_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                package_code: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },
                package_type: {
                    value: "",
                    err: false,
                    msjErr: ''
                },

                count: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },
                identification: {
                    value: "",
                    err: false,
                    msjErr: ''
                },
                sub_continent: {
                    value: "",
                    err: false,
                    msjErr: ''
                },
                items: [{
                    count: {
                        value: '',
                        err: false,
                        errmsj: 'Debe ingresar una cantidad'
                    },
                    number_item: {
                        value: '',
                        err: false,
                        errmsj: 'Debe ingresar un producto'
                    },
                    name_item: {
                        value: '',
                        err: false,
                        errmsj: 'Debe ingresar un producto'
                    },
                    weight_item: {
                        value: '',
                        err: false,
                        errmsj: 'Debe ingresar un producto'
                    },
                    value_fob: {
                        value: '',
                        err: false,
                        errmsj: 'Debe ingresar un producto'
                    }
                }]
            },
            verModal: false,
            esEditable: true,
            agregar: true,
            isSecureContexttems: [],
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            idClient: 0,
            packages: [
            ],
            package: {},
            columns: [
                { field: "id_shipping_package", title: "ID" },
                { field: "package_number", title: "Número de paquete" },
                { field: "package_type", title: "Tipo paquete" },
                { field: 'sub_continent', title: 'Continente' },
                { field: "quantity", title: "Cantidad" },
                { field: "identification", title: "Número de identificacion" },
                { field: "package_code", title: "Código de paquete" },
                { field: "action", title: "" }
            ]
        }
    },
    mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        this.idClient = this.user.id_company;
        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');

        if (parts.length == 4) {
            if (!isNaN(Number(parts[3]))) {
                this.id_shipping = Number(parts[3]);
                this.getPackage(this.id_shipping);
            }
        }

    },
    computed: {

    },
    methods: {
        showAddPackage() {
            this.agregar = true;
            this.esEditable = true;
            this.verModal = true;
        },

        isReadOnly() {
            return this.esEditable;
        },

        async editPackage(value, editable) {
            const self = this;
            self.agregar = false;
            self.esEditable = editable;
            try {
                const response = await axios.get('/api/get-shipping-package/' + value, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.package = response.data[0];

                self.modalForm.package_number.value = this.package.package_number;
                self.modalForm.package_code.value = this.package.package_code;
                self.modalForm.package_type.value = this.package.package_type;
                self.modalForm.sub_continent.value = this.package.sub_continent;
                self.modalForm.count.value = this.package.quantity;
                self.modalForm.identification.value = this.package.identification;
                self.modalForm.items = [];
                this.package.shipping_items.map((item, ind) => {
                    let itemNew = {}
                    itemNew.count = {
                        value: item.quantity,
                        err: false,
                        errmsj: 'Debe ingresar una cantidad'
                    },
                        itemNew.number_item = {
                            value: item.item_number,
                            err: false,
                            errmsj: 'Debe ingresar un producto'
                        },
                        itemNew.name_item = {
                            value: item.item_name,
                            err: false,
                            errmsj: 'Debe ingresar un producto'
                        },
                        itemNew.weight_item = {
                            value: item.gross_weight,
                            err: false,
                            errmsj: 'Debe ingresar un producto'
                        },
                        itemNew.value_fob = {
                            value: item.fob_value,
                            err: false,
                            errmsj: 'Debe ingresar un producto'
                        }
                    self.modalForm.items.push(itemNew)
                })
                self.verModal = true;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }

        },
        backPage() {
            window.history.back();
        },
        addProv() {
            this.modalForm.items.push({
                count: {
                    value: '',
                    err: false,
                    errmsj: 'Debe ingresar una cantidad'
                },
                number_item: {
                    value: '',
                    err: false,
                    errmsj: 'Debe ingresar un producto'
                },
                name_item: {
                    value: '',
                    err: false,
                    errmsj: 'Debe ingresar un producto'
                },
                weight_item: {
                    value: '',
                    err: false,
                    errmsj: 'Debe ingresar un producto'
                },
                value_fob: {
                    value: '',
                    err: false,
                    errmsj: 'Debe ingresar un producto'
                }
            })
        },
        deleteProv(prov) {
            const self = this;
            self.modalForm.items = this.modalForm.items.filter(ind => ind != prov)
        },
        async getPackage(idPackage) {
            try {
                const response = await axios.get('/api/get-package/' + idPackage, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });

                this.packages = response.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        closeModal() {
            const self = this;
            self.verModal = false;
        },
        updateCollapsed(value) {

            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {

            this.pinned = value; // Actualiza el estado
        },
        async eliminarPaquete(id_shipping_package) {
            const self = this;
            this.$swal.fire({
                icon: "warning",
                title: "Estas seguro que deseas eliminar este paquete?",
                showConfirmButton: true,
                confirmButtonText: "si, eliminar",
                showCancelButton: true,
                cancelButtonText: "no"
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/api/deletePackage/${id_shipping_package}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        console.log(response);
                        if (response.status == 201) {
                            this.getPackage(this.id_shipping);
                            this.verModal = false;
                            this.$swal.fire({
                                icon: "success",
                                title: "Paquete eliminado con exito",
                            })
                        } else {

                        }
                    }).catch(function (error) {

                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        }
                    });
                }
            });
        },
        updatePaquete() {
            const self = this;
            const req = { form: self.modalForm, id_shipping_package: self.package.id_shipping_package };
            axios.post('/api/updatePackage',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (response.status == 200) {
                        this.getPackage(this.id_shipping);
                        this.verModal = false;
                        this.$swal.fire({
                            icon: "success",
                            title: "Paquete actualizado con exito",
                        })
                    } else {

                    }
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });
        },
        crearPaquete() {
            const self = this;
            const req = { form: self.modalForm, id_shipping: self.id_shipping };
            axios.post('/api/createPackage',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (response.status == 200) {
                        this.getPackage(this.id_shipping);
                        this.verModal = false;
                        this.$swal.fire({
                            icon: "success",
                            title: "Paquete creado con exito",
                        })
                    } else {

                    }
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });


        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3"><b>Editar despacho Nro {{ id_shipping }}</b></h2>

                    </div>
                    <div class="row pt-4 px-1">
                        <div class="col-3 mb-4">
                            <label for="client" class="form-label">Cliente</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.client.value">
                                <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="shipping" class="form-label">Número despacho</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.shipping.err }"
                                id="shipping" placeholder="" v-model="dataForm.shipping.value" @blur="handlerBlur">
                            <span v-if="dataForm.shipping.err" class="text-danger">Debe ingresar un
                                número de despacho</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="booking" class="form-label">Número reserva</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.booking.err }"
                                id="booking" placeholder="" v-model="dataForm.booking.value" @blur="handlerBlur">
                            <span v-if="dataForm.booking.err" class="text-danger">Debe ingresar un
                                número de reserva</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="operationType" class="form-label">Tipo operación</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.operationType.err }" id="operationType"
                                placeholder="" v-model="dataForm.operationType.value" @blur="handlerBlur">
                            <span v-if="dataForm.operationType.err" class="text-danger">Debe ingresar un
                                tipo de operación</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="aduana" class="form-label">Aduana</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.aduana.err }"
                                id="aduana" placeholder="" v-model="dataForm.aduana.value" @blur="handlerBlur">
                            <span v-if="dataForm.aduana.err" class="text-danger">Debe ingresar una
                                aduana</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="agentAduana" class="form-label">Agente aduana</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.agentAduana.err }" id="agentAduana" placeholder=""
                                v-model="dataForm.agentAduana.value" @blur="handlerBlur">
                            <span v-if="dataForm.agentAduana.err" class="text-danger">Debe ingresar un agente de
                                aduana</span>
                        </div>

                        <div class="col-md-3 mb-4">
                            <label for="clientReference" class="form-label">Referencia cliente</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.clientReference.err }" id="clientReference"
                                placeholder="" v-model="dataForm.clientReference.value" @blur="handlerBlur">
                            <span v-if="dataForm.clientReference.err" class="text-danger">Debe ingresar un cliente de
                                referencia</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="portShipping" class="form-label">Puerto de embarque</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.portShipping.err }" id="portShipping" placeholder=""
                                v-model="dataForm.portShipping.value" @blur="handlerBlur">
                            <span v-if="dataForm.portShipping.err" class="text-danger">Debe ingresar un puerto de
                                embarque</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="destinationPort" class="form-label">Puerto de destino</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.destinationPort.err }" id="destinationPort"
                                placeholder="" v-model="dataForm.destinationPort.value" @blur="handlerBlur">
                            <span v-if="dataForm.destinationPort.err" class="text-danger">Debe ingresar un puerto de
                                destino</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="destinationCountry" class="form-label">País de destino</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.destinationCountry.err }" id="destinationCountry"
                                placeholder="" v-model="dataForm.destinationCountry.value" @blur="handlerBlur">
                            <span v-if="dataForm.destinationCountry.err" class="text-danger">Debe ingresar un país de
                                destino</span>
                        </div>

                        <hr>
                        <div class="col-md-3 mb-4">
                            <label for="consig" class="form-label">Consignatario</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.consig.err }"
                                id="consig" placeholder="" v-model="dataForm.consig.value" @blur="handlerBlur">
                            <span v-if="dataForm.consig.err" class="text-danger">Debe ingresar un consignatario</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="totalWeight" class="form-label">Total peso bruto</label>
                            <input type="number" class="form-control"
                                :class="{ 'border-danger': dataForm.totalWeight.err }" id="totalWeight" placeholder=""
                                v-model="dataForm.totalWeight.value" @blur="handlerBlur">
                            <span v-if="dataForm.totalWeight.err" class="text-danger">Debe ingresar un total peso</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="totalFob" class="form-label">Total fob</label>
                            <input type="number" class="form-control"
                                :class="{ 'border-danger': dataForm.totalFob.err }" id="totalFob" placeholder=""
                                v-model="dataForm.totalFob.value" @blur="handlerBlur">
                            <span v-if="dataForm.totalFob.err" class="text-danger">Debe ingresar un total peso</span>
                        </div>

                        <div class="col-md-3 mb-4">
                            <label for="petitioner" class="form-label">Peticionario</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.petitioner.err }" id="petitioner" placeholder=""
                                v-model="dataForm.petitioner.value" @blur="handlerBlur">
                            <span v-if="dataForm.petitioner.err" class="text-danger">Debe ingresar un
                                peticionario</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="date" class="form-label">Fecha</label>
                            <div class="input-group mb-3">
                                <VueDatePicker v-model="dataForm.date.value" locale="es" format="dd/MM/yyyy">
                                </VueDatePicker>
                            </div>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="sender" class="form-label">Remitente</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.sender.err }"
                                id="sender" placeholder="" v-model="dataForm.sender.value" @blur="handlerBlur">
                            <span v-if="dataForm.sender.err" class="text-danger">Debe ingresar un remitente</span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="comodato" class="form-label">Comodato</label>
                            <textarea type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.comodato.err }" id="comodato"
                                placeholder="Ingrese un mensaje" v-model="dataForm.comodato.value" @blur="handlerBlur">
                            </textarea>
                            <p v-if="dataForm.comodato.err" class="text-danger">Debe ingresar una
                                descripción del comodato</p>
                        </div>

                        <div class="d-flex justify-content-between">
                            <button class="btn btn-primary" @click="updateShipping">Guardar cambios</button>
                            <button class="btn btn-danger" @click="goBack">Volver</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import DropFile from './../component/DropFile.vue';
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";
DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DataTable,
        DataTablesCore,
        NavBar,
        BarTop,
        DropFile,
        Vue3Datatable
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            companies: [

            ],
            clients: [

            ],
            verBoarding: false,
            columns: [

                { field: "boarding_number", title: "Número embarque" },
                { field: "ship_name", title: "Nombre Vessel" },
                { field: "origin", title: "Origen" },
                { field: "destination", title: "Destino" },
                { field: "actual_delivery_date", title: "Fecha actual de despacho" },
                { field: "estimated_delivery_date", title: "Fecha estimada de despacho" },
                { field: 'select', title: 'Seleccionar' }
            ],
            board: [],
            transports: [],
            idClient: 0,
            newBoard: false,
            dataForm2: {
                id_transport_company: {
                    value: 0,
                    err: false,
                    msjErr: ''
                }, origin: {
                    value: '',
                    err: false,
                    msjErr: ''
                }, boarding_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                }, destination: {
                    value: '',
                    err: false,
                    msjErr: ''
                }, estimated_delivery_date: {
                    value: '',
                    err: false,
                    msjErr: ''
                }, actual_delivery_date: {
                    value: '',
                    err: false,
                    msjErr: ''
                }, travel_number: {
                    value: 0,
                    err: false,
                    msjErr: ''
                }, ship_name: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
            },
            dataForm: {
                sending: false,
                client: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                shipping: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                booking: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                operationType: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                aduana: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                agentAduana: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                clientReference: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                portShipping: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                destinationPort: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                destinationCountry: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                consig: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                totalWeight: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },
                totalFob: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },

                petitioner: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                date: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                boardSelect: {},
                boardSelectErr: false,
                isBoard: false,
                sender: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                comodato: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                description: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                files: [],
                updateDus: false

            }
        }
    },
    mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        this.idClient = this.user.company.id;
        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');

        if (parts.length == 4) {
            if (!isNaN(Number(parts[3]))) {
                this.id_shipping = Number(parts[3]);
                this.getShipping(this.id_shipping);
            }
        }
        this.getBoards();
        this.getTransports();
        this.getClients();
    },
    computed: {

    },
    methods: {
        cancelBtn(){
            window.location.href = '../../despachos'
        },
        async getShipping(id_shipping) {
            const self = this;
            try {
                const response = await axios.get('/api/getShipping/' + id_shipping, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                const shipping = response.data.data;

                self.dataForm.client.value = shipping.id_client
                self.dataForm.shipping.value = shipping.shipping_number
                self.dataForm.booking.value = shipping.booking_number
                self.dataForm.operationType.value = shipping.type_operation
                self.dataForm.aduana.value = shipping.aduana
                self.dataForm.agentAduana.value = shipping.custom_broker
                self.dataForm.clientReference.value = shipping.clients_reference
                self.dataForm.portShipping.value = shipping.port_departure_shipment
                self.dataForm.destinationPort.value = shipping.port_arribe_shipment
                self.dataForm.destinationCountry.value = shipping.country_destination
                self.dataForm.consig.value = shipping.consignee
                self.dataForm.totalWeight.value = shipping.total_gross_weight
                self.dataForm.totalFob.value = shipping.total_fob
                self.dataForm.petitioner.value = shipping.id_user_petitioner
                self.dataForm.date.value = shipping.estimated_delivery_date

                self.dataForm.sender.value = shipping.forwarder;
                self.dataForm.comodato.value = shipping.bailment;
                self.dataForm.description.value = shipping.observations
                if (shipping.id_boarding) {
                    self.dataForm.isBoard = true
                    self.dataForm.boardSelect = {
                        actual_delivery_date: shipping.actual_delivery_date, boarding_number: shipping.boarding_number, created_at: shipping.created_at
                        , destination: shipping.destination, estimated_delivery_date: shipping.estimated_delivery_date, id_boarding: shipping.id_boarding,
                        id_transport_company: shipping.id_transport_company, origin: shipping.origin, ship_name: shipping.ship_name, travel_number: shipping.travel_number,
                        updated_at: shipping.updated_at
                    }
                }


            } catch (error) {
                console.error('Error fetching concepts:', error);
            }

        },
        async getTransports() {
            try {
                const response = await axios.get('/api/transport-company', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.transports = response.data;
            
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        getClients() {
            const self = this;
            axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                if (response.status == 200) {
                    self.clients = response.data;
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });

        },
        adminPackage(){
            window.location.href = '../../despachos/package/' + this.id_shipping
        },
        async getBoards() {

            try {
                const response = await axios.get('/api/boardings', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.board = response.data;
                console.log(this.board)
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        closeModal() {
            const self = this;
            self.verBoarding = false;
        },
        isSelectedBoard(data) {

            return this.dataForm.boardSelect.id_boarding == data.id_boarding
        },

        toggleSelectionBoard(data) {
            const self = this;
            self.verBoarding = false;
            if (self.dataForm.boardSelect.id_boarding == data.id_boarding) {
                this.$swal.fire({
                    icon: "warning",
                    title: "Estas seguro que deseas desasociar este embarque?",
                    descripcion: "para actualizar este despacho necesitas tener asociado un embarque",
                    showConfirmButton: true,
                    confirmButtonText: "si, desasociar",
                    showCancelButton: true,
                    cancelButtonText: "no, cancelar"
                }).then((result) => {
                    if (result.isConfirmed) {
                        self.dataForm.isBoard = false;
                        self.dataForm.boardSelect = {};
                    } else {
                        self.verBoarding = true
                    }
                });

            } else {
                this.$swal.fire({
                    icon: "warning",
                    title: "Estas seguro que deseas asociar este embarque?",
                    showConfirmButton: true,
                    confirmButtonText: "si, asociar",
                    showCancelButton: true,
                    cancelButtonText: "no, cancelar"
                }).then((result) => {
                    if (result.isConfirmed) {
                        self.dataForm.isBoard = true;
                        self.dataForm.boardSelect = data;
                    } else {
                        self.verBoarding = true
                    }
                });

            }
        },

        updateCollapsed(value) {
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {

            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
        },
        create() {
            const self = this;
            self.dataForm.sending = true;
            let errFound = false;
            const validateField = (field) => {
                if (field.value === '' || field.value === 0 || !field.value) {
                    field.err = true;
                    field.msjErr = 'Este campo es obligatorio';
                    console.log(field)
                    errFound = true;
                } else {
                    field.err = false;
                    field.msjErr = '';
                }
            };

            if (Object.keys(self.dataForm.boardSelect).length === 0 && !self.newBoard) {
                self.dataForm.boardSelectErr = true;
                errFound = true;
            } else {
                self.dataForm.boardSelectErr = false;
                errFound = false;
            }
            if (self.newBoard) {
                // Validar campos en dataForm2
                Object.keys(this.dataForm2).forEach(key => {
                    if (typeof this.dataForm2[key] === 'object' && 'value' in this.dataForm2[key]) {
                        validateField(this.dataForm2[key]);
                       
                    }
                });
            }
            Object.keys(this.dataForm).forEach(key => {
                if (typeof this.dataForm[key] === 'object' && 'value' in this.dataForm[key]) {
                    validateField(this.dataForm[key]);
                   
                }
            });
            // Verificar si hay errores
            if (errFound) {
                return false
            }
            this.$swal({
                title: 'Actualizando shipping...'
            });
            this.$swal.showLoading();

            const req = { id_shipping: this.id_shipping, form: self.dataForm, form2: this.dataForm2, newBoard: this.newBoard, idUser: this.user.id_user }

            axios.post('/api/updateShipping',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (response.status == 200) {

                        this.$swal.close();
                        this.$swal.fire({
                            icon: "success",
                            title: "Despacho actualizado con exito",
                            showConfirmButton: true,
                            confirmButtonText: "Ádministrar paquetes",
                            showCancelButton: true,
                            cancelButtonText: "Cerrar sin paquetes"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '../../despachos/package/' + response.data.id_shipping
                            } else {
                                window.location.href = '../../despachos';
                            }
                        });
                    } else {

                    }
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });


        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>